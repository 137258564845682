import React, { useCallback, useEffect, useRef, useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import {
  isProductOptionsDisabled,
  selectPouchOptionsBasedOnSelectedProduct,
  selectSelectedTypeData,
  selectSpecificStepDataValue,
  selectSpecificStepModelValue,
  setProductStepProgress,
  setSpecificStepDataValue
} from "../../../../store/wizard/wizardSlice";
import { ProductOptionTab } from "../../../../types/product-step";
import { WizardStepType } from "../../../../types/wizard";
import { BlockTitle } from "../shared/BlockTitle";
import { NextStepButton } from "../shared/NextStepButton";
import { ProductOptionsMaterialSelector } from "./ProductOptionsMaterialSelector";
import { ProductOptionsSelector } from "./ProductOptionsSelector";
import { ProductOptionsSizeSelector } from "./ProductOptionsSizeSelector";
import { ProductOptionsTypeSelector } from "./ProductOptionsTypeSelector";
import { ProductOptionTabs } from "./ProductOptionTabs";

const getNextTab = (current: ProductOptionTab) => {
  switch (current) {
    case ProductOptionTab.Type:
      return ProductOptionTab.Material;
    case ProductOptionTab.Material:
      return ProductOptionTab.Size;
    case ProductOptionTab.Size:
      return ProductOptionTab.Options;
    case ProductOptionTab.Options:
      return null;
    default:
      return null;
  }
};

export const ProductOptions = () => {
  const { t } = useTranslation(["global"]);

  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const tabProgress = useSelector(selectSpecificStepModelValue(WizardStepType.Product, "tabProgress"));

  const [preventAutoContinue, setPreventAutoContinue] = useState({
    [ProductOptionTab.Type]: false,
    [ProductOptionTab.Material]: false,
    [ProductOptionTab.Size]: false,
    [ProductOptionTab.Options]: false
  });
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

  const activeTab = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "activeOptionTab"));
  const selectedProductType = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "type"));
  const selectedMaterial = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "material"));
  const disabled = useSelector(isProductOptionsDisabled);
  const selectedTypeData = useSelector(selectSelectedTypeData);
  const pouchOptions = useSelector(selectPouchOptionsBasedOnSelectedProduct);

  const selectedSubType = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "subType"));
  const typeSelectorRef = useRef<any>(null);
  const materialSelectorRef = useRef<any>(null);
  const sizeSelectorRef = useRef<any>(null);
  const optionsSelectorRef = useRef<any>(null);

  const scrollToElement = useCallback((element: any) => {
    const y = element.getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top: y - 60,
      behavior: "smooth"
    });
  }, []);

  const setActiveTab = useCallback(
    (tab: ProductOptionTab) => {
      dispatch(
        setSpecificStepDataValue({
          type: WizardStepType.Product,
          option: "activeOptionTab",
          value: tab
        })
      );

      setTimeout(() => {
        if (isMobile) {
          switch (tab) {
            case ProductOptionTab.Material:
              if (materialSelectorRef.current) {
                scrollToElement(materialSelectorRef.current);
              }
              break;
            case ProductOptionTab.Size:
              if (sizeSelectorRef.current) {
                scrollToElement(sizeSelectorRef.current);
              }
              break;
            case ProductOptionTab.Options:
              if (optionsSelectorRef.current) {
                scrollToElement(optionsSelectorRef.current);
              }
              break;
            default:
          }
        }
      }, 10);
    },
    [typeSelectorRef, materialSelectorRef, sizeSelectorRef, optionsSelectorRef, isMobile]
  );

  const handleNextTabClick = useCallback(() => {
    const nextTab = getNextTab(activeTab);
    if (nextTab === null) return;
    setActiveTab(nextTab);
  }, [activeTab]);

  const goToNextTab = useCallback(() => {
    if (preventAutoContinue[activeTab] && !isMobile) return;
    const nextTab = getNextTab(activeTab);
    if (nextTab) {
      setTimeout(() => {
        dispatch(
          setProductStepProgress({
            type: activeTab,
            status: true
          })
        );

        setActiveTab(nextTab);
      }, 100);
    }
  }, [activeTab, setActiveTab, preventAutoContinue, isMobile]);

  const getElement = useCallback(
    (tab: ProductOptionTab) => {
      switch (tab) {
        case ProductOptionTab.Type:
          return (
            <ProductOptionsTypeSelector
              ref={typeSelectorRef}
              onTypeChange={(_, isMaterialVariations) => {
                setIsNextButtonDisabled(isMaterialVariations);
              }}
              onSubTypeChange={() => {
                setIsNextButtonDisabled(false);
              }}
              onSelect={(typeCode) => {
                const selectMandatoryOptions = () => {
                  if (
                    typeCode === "packaging_type.stock" ||
                    typeCode === "packaging_type.screen" ||
                    typeCode === "packaging_type.label" ||
                    typeCode === "packaging_type.label_2"
                  ) {
                    const defaultOptions: string[] = pouchOptions.map((option) => {
                      return option.uuid;
                    });
                    dispatch(
                      setSpecificStepDataValue({
                        type: WizardStepType.Product,
                        option: "options",
                        subOption: "options",
                        value: defaultOptions as string[]
                      })
                    );
                  }
                };

                if (isMobile) {
                  setActiveTab(ProductOptionTab.Material);
                  selectMandatoryOptions();
                  return;
                }

                if (activeTab !== ProductOptionTab.Type) return;
                selectMandatoryOptions();
                goToNextTab();
              }}
            />
          );
        case ProductOptionTab.Material:
          return (
            <ProductOptionsMaterialSelector
              ref={materialSelectorRef}
              onSelect={() => {
                if (activeTab !== ProductOptionTab.Material) return;
                setIsNextButtonDisabled(false);
                goToNextTab();
              }}
            />
          );
        case ProductOptionTab.Size:
          return (
            <ProductOptionsSizeSelector
              ref={sizeSelectorRef}
              onSelect={() => {
                if (activeTab !== ProductOptionTab.Size) return;
                setIsNextButtonDisabled(false);
                goToNextTab();
              }}
            />
          );
        case ProductOptionTab.Options:
          return <ProductOptionsSelector ref={optionsSelectorRef} />;
        default:
          return <></>;
      }
    },
    [
      activeTab,
      preventAutoContinue,
      optionsSelectorRef,
      materialSelectorRef,
      typeSelectorRef,
      sizeSelectorRef,
      pouchOptions
    ]
  );

  useEffect(() => {
    if (tabProgress) {
      setPreventAutoContinue({
        [ProductOptionTab.Type]: tabProgress[ProductOptionTab.Type],
        [ProductOptionTab.Material]: tabProgress[ProductOptionTab.Material],
        [ProductOptionTab.Size]: tabProgress[ProductOptionTab.Size],
        [ProductOptionTab.Options]: tabProgress[ProductOptionTab.Options]
      });
    }
  }, [tabProgress]);

  useEffect(() => {
    if (activeTab === ProductOptionTab.Material && !selectedMaterial) {
      setIsNextButtonDisabled(true);
    }
  }, [activeTab, selectedMaterial]);

  useEffect(() => {
    if (selectedTypeData && selectedTypeData.isMaterialVariations && !selectedSubType && !isNextButtonDisabled) {
      setIsNextButtonDisabled(true);
    }
  }, [selectedTypeData, selectedSubType, isNextButtonDisabled, selectedProductType]);

  return (
    <div
      className={classNames("product-step__options", "border-top-to-md", "right-border", {
        "disabled-block": disabled
      })}
    >
      <BlockTitle title={t("wizard.steps.product.options")} />

      {tabProgress !== null ? (
        <ProductOptionTabs
          activeTab={activeTab}
          disabled={disabled}
          onChange={(tab) => {
            setPreventAutoContinue((pv) => ({
              ...pv,
              [tab]: true
            }));
            setActiveTab(tab);
          }}
          progress={tabProgress}
          getElement={getElement}
        />
      ) : (
        ""
      )}

      {!isMobile ? getElement(activeTab) : ""}

      {!isMobile && preventAutoContinue[activeTab] ? (
        <NextStepButton
          onClick={() => {
            handleNextTabClick();
          }}
          disabled={isNextButtonDisabled}
        />
      ) : (
        ""
      )}
    </div>
  );
};
