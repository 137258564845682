import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { ReactComponent as EyeIconSvg } from "../../assets/images/icons/eye-icon.svg";
import { Button } from "../Button/Button";
import { Modal } from "../Modal/Modal";
// eslint-disable-next-line import/no-cycle
import { ImageCarousel } from "./StepComponents/shared/ImageCarousel";

export const ViewPackageButton = () => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation(["global"]);

  return (
    <div className="wizard__view-package-button">
      <Modal
        show={showModal}
        className="package-modal"
        headerTitle={t("layout.modal.my_package")}
        preventContentWrapper
        onHide={() => {
          setShowModal(false);
        }}
      >
        <ImageCarousel />
      </Modal>

      <Button
        size="lg"
        variant="green"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <EyeIconSvg /> <span className="font-padding">{t("layout.modal.view_my_package")}</span>
      </Button>
    </div>
  );
};
