import React from "react";

import classNames from "classnames";

interface Props {
  name: string;
  isWhite?: boolean;
}

export const Icon: React.FC<Props> = ({ name, isWhite }) => {
  return (
    <i
      className={classNames("icon", `icon-${name}`, {
        "text-white": isWhite
      })}
    />
  );
};
