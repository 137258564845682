import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import {
  resetProductStepProgress,
  selectPackagingTypesBasedOnSelectedProduct,
  selectSpecificStepDataValue,
  selectSpecificWizardApiDataValue,
  setSpecificStepDataValue
} from "../../../../store/wizard/wizardSlice";
import { WizardStepType } from "../../../../types/wizard";
import { getTranslationFromLangObj } from "../../../../utilities/language";
import { RadioButtons } from "../../../FormGroup/RadioButtons/RadioButtons";
import { ProductOptionContent } from "./shared/ProductOptionContent";
import { ProductOptionContentBody } from "./shared/ProductOptionContentBody";
import { ProductOptionDescription } from "./shared/ProductOptionDescription";

interface Props {
  onSelect: (typeCode: string, subTypeCode?: string) => void;
  onTypeChange: (typeId: string, isMaterialVariations: boolean) => void;
  onSubTypeChange: (subTypeId: string) => void;
}

export const ProductOptionsTypeSelector: React.FC<Props & { ref: React.Ref<HTMLDivElement> }> = React.forwardRef(
  ({ onSelect, onTypeChange, onSubTypeChange }, ref: React.Ref<HTMLDivElement>) => {
    const { t } = useTranslation(["global"]);

    const SelectedProductTypeCode = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "typeCode"));
    const selectedType = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "type"));
    const selectedTypeCode = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "typeCode"));
    const selectedSubType = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "subType"));
    const selectedMaterial = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "material"));
    // const packagingTypes = useSelector(selectSpecificWizardApiDataValue("packagingTypes"));
    const packagingTypes = useSelector(selectPackagingTypesBasedOnSelectedProduct);
    const materialVariations = useSelector(selectSpecificWizardApiDataValue("materialVariations"));
    const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
    const dispatch = useDispatch();

    const handleTypeChange = useCallback(
      async (value: string) => {
        if (packagingTypes === null) return;
        const target = packagingTypes.find((type) => type.uuid === value);

        if (!target) return;

        dispatch(
          setSpecificStepDataValue({
            type: WizardStepType.Product,
            option: "options",
            subOption: "type",
            value
          })
        );

        dispatch(
          setSpecificStepDataValue({
            type: WizardStepType.Product,
            option: "options",
            subOption: "typeCode",
            value: target.code
          })
        );

        if (selectedSubType) {
          dispatch(
            setSpecificStepDataValue({
              type: WizardStepType.Product,
              option: "options",
              subOption: "subType",
              value: undefined
            })
          );

          dispatch(
            setSpecificStepDataValue({
              type: WizardStepType.Product,
              option: "options",
              subOption: "subTypeCode",
              value: undefined
            })
          );
        }

        dispatch(
          setSpecificStepDataValue({
            type: WizardStepType.Product,
            option: "options",
            subOption: "options",
            value: []
          })
        );

        dispatch(
          setSpecificStepDataValue({
            type: WizardStepType.Product,
            option: "options",
            subOption: "size",
            value: ""
          })
        );

        if (selectedMaterial) {
          dispatch(
            setSpecificStepDataValue({
              type: WizardStepType.Product,
              option: "options",
              subOption: "material",
              value: ""
            })
          );
        }

        if (isMobile) {
          dispatch(resetProductStepProgress());
        }

        onTypeChange(value, target.isMaterialVariations);

        if (!target.isMaterialVariations) {
          onSelect(target.code);
        }
      },
      [packagingTypes, onSelect, onTypeChange, onSubTypeChange, selectedSubType, selectedMaterial, isMobile]
    );

    const handleSubTypeChange = useCallback(
      (value: string) => {
        dispatch(
          setSpecificStepDataValue({
            type: WizardStepType.Product,
            option: "options",
            subOption: "subType",
            value
          })
        );

        const code = materialVariations?.find((variation) => variation.uuid === value)?.code;

        if (code) {
          dispatch(
            setSpecificStepDataValue({
              type: WizardStepType.Product,
              option: "options",
              subOption: "subTypeCode",
              value: code
            })
          );
          dispatch(
            setSpecificStepDataValue({
              type: WizardStepType.Product,
              option: "options",
              subOption: "material",
              value: ""
            })
          );
        }

        onSelect(selectedTypeCode, code);

        onSubTypeChange(value);
      },
      [onSelect, onSubTypeChange, selectedTypeCode]
    );

    return (
      <ProductOptionContent ref={ref}>
        <ProductOptionDescription>
          {t(
            `wizard.steps.product.type_tab.${
              SelectedProductTypeCode.length ? SelectedProductTypeCode : "default"
            }.description`
          )}
        </ProductOptionDescription>
        <ProductOptionContentBody>
          {materialVariations !== null && packagingTypes !== null ? (
            <RadioButtons
              itemSeparator
              name="option_type"
              items={[
                ...packagingTypes.map((packagingType) => ({
                  value: packagingType.uuid,
                  text: getTranslationFromLangObj(packagingType.nameTrans),
                  subText: getTranslationFromLangObj(packagingType.shortDescriptionTrans),
                  tooltip: getTranslationFromLangObj(packagingType.descriptionTrans),
                  ...(packagingType.isMaterialVariations
                    ? {
                        childrenWhenChecked: (
                          <>
                            <RadioButtons
                              name="label_sub_type"
                              inline
                              items={[
                                ...materialVariations.map((materialVariation) => ({
                                  value: materialVariation.uuid,
                                  text: getTranslationFromLangObj(materialVariation.nameTrans),
                                  tooltip: getTranslationFromLangObj(materialVariation.descriptionTrans)
                                }))
                              ]}
                              value={selectedSubType || ""}
                              onChange={(e) => {
                                handleSubTypeChange(e.target.value);
                              }}
                            />
                          </>
                        )
                      }
                    : {})
                }))
              ]}
              value={selectedType}
              onChange={(e) => {
                handleTypeChange(e.target.value);
              }}
            />
          ) : (
            ""
          )}
        </ProductOptionContentBody>
      </ProductOptionContent>
    );
  }
);
