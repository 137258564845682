import React, { useEffect, useState } from "react";

import { FormGroup } from "../FormGroup";
import { FormGroupLabel } from "../FormGroupLabel";

interface Props {
  min: number;
  max: number;
  value: number;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputSlider: React.FC<Props> = ({ min, label, max, value, onChange }) => {
  const [backgroundStyle, setBackgroundStyle] = useState("");

  useEffect(() => {
    const linearGradientValue = ((value - min) / (max - min)) * 100;
    setBackgroundStyle(
      `linear-gradient(to right, #08bcb2 0%, #82CFD0 ${linearGradientValue}%, #f5f5f7 ${linearGradientValue}%, #f5f5f7 100%)`
    );
  }, [value, min, max]);

  return (
    <FormGroup>
      {label ? <FormGroupLabel>{label}</FormGroupLabel> : ""}
      <div className="slider-container">
        <input
          type="range"
          style={{ background: backgroundStyle }}
          onChange={onChange}
          min={min}
          max={max}
          value={value}
          className="form-control"
        />
      </div>
    </FormGroup>
  );
};
