import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useSizesControls } from "../../../../hooks/useSizesControls";
import {
  selectSizesBasedOnSelectedProduct,
  selectSpecificStepDataValue,
  setSpecificStepDataValue
} from "../../../../store/wizard/wizardSlice";
import { WizardStepType } from "../../../../types/wizard";
import { RadioButtons } from "../../../FormGroup/RadioButtons/RadioButtons";
import { ProductOptionContent } from "./shared/ProductOptionContent";
import { ProductOptionContentBody } from "./shared/ProductOptionContentBody";
import { ProductOptionDescription } from "./shared/ProductOptionDescription";

interface Props {
  onSelect: () => void;
}

export const ProductOptionsSizeSelector: React.FC<Props & { ref: React.Ref<HTMLDivElement> }> = React.forwardRef(
  ({ onSelect }, ref: React.Ref<HTMLDivElement>) => {
    const { t } = useTranslation(["global"]);
    const selectedSize = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "size"));
    const productCode = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "typeCode"));
    const sizes = useSelector(selectSizesBasedOnSelectedProduct);
    const dispatch = useDispatch();
    const { getSizesText, getSizesTooltip } = useSizesControls();

    const handleChange = useCallback(
      (value: string) => {
        dispatch(
          setSpecificStepDataValue({
            type: WizardStepType.Product,
            option: "options",
            subOption: "size",
            value
          })
        );

        if (sizes) {
          const target = sizes.find((size) => size.uuid === value);

          if (target) {
            dispatch(
              setSpecificStepDataValue({
                type: WizardStepType.Product,
                option: "options",
                subOption: "sizeData",
                value: [target.width, target.height, target.depth, target.grams, target.ml]
              })
            );
          }
        }

        onSelect();
      },
      [onSelect, sizes]
    );

    return (
      <ProductOptionContent ref={ref}>
        <ProductOptionDescription>{t("wizard.steps.product.size_tab.description")}</ProductOptionDescription>
        <ProductOptionContentBody>
          <RadioButtons
            itemSeparator
            name="option_size"
            items={[
              ...(sizes !== null
                ? sizes.map(({ uuid, grams, width, height, depth, ml }) => ({
                    value: uuid,
                    text: getSizesText(productCode, grams, ml, width, height, depth),
                    tooltip: getSizesTooltip(productCode, grams, ml)
                  }))
                : [])
            ]}
            value={selectedSize}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
          />
        </ProductOptionContentBody>
      </ProductOptionContent>
    );
  }
);
