import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { getFormattedPercent } from "../../../../utilities/formatter";

interface Props {
  total?: string;
  shipping?: string;
  vat?: string;
  vatPerc?: number;
  totalToPay?: string;
}

export const OrderSummaryBlock: React.FC<Props> = ({ total, shipping, vat, vatPerc, totalToPay }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { t } = useTranslation(["global"]);

  const netTotal = useMemo(() => {
    if (total && shipping) {
      return (Number(total) + Number(shipping)).toFixed(2);
    }

    return "";
  }, [total, shipping]);

  return (
    <div className="product-summary">
      <ul>
        <li>
          <span>{t("wizard.summary.total")}:</span> <span>{total ? `${total} €` : "-"}</span>
        </li>
        <li>
          <span>{t("wizard.summary.shipping")}:</span> <span>{shipping ? `${shipping} €` : "-"}</span>
        </li>
        <li>
          <span>{t("wizard.summary.net_total")}</span>
          <span>{netTotal ? `${netTotal} €` : "-"}</span>
        </li>
        <li className="mt-3">
          <span>
            {t("wizard.summary.vat")}
            {vatPerc ? `(${getFormattedPercent(vatPerc)}%)` : ""}:
          </span>
          <span>{vat ? `${vat} €` : "-"}</span>
        </li>
        <li className="product-summary__bold">
          <span>{isMobile ? t("wizard.summary.total") : t("wizard.summary.total_to_pay")}:</span>
          <span>{totalToPay ? `${totalToPay} €` : "-"}</span>
        </li>
      </ul>
    </div>
  );
};
