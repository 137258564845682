import React from "react";

import classNames from "classnames";

import { FormGroup } from "../FormGroup";
import { FormGroupLabel } from "../FormGroupLabel";

interface Props {
  type: string;
  name?: string;
  placeholder?: string;
  value: string;
  required?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  appendix?: any;
  variant?: "gray" | "default";
  className?: string;
  label?: any;
  size?: "sm" | "md" | "lg";
}

export const InputField: React.FC<Props> = ({
  type,
  variant,
  required,
  appendix,
  name,
  size,
  placeholder,
  value,
  label,
  className,
  onChange
}) => {
  return (
    <FormGroup
      className={classNames(
        {
          "form-group--appendix": !!appendix
        },
        size ? `form-group--${size}` : "",
        className
      )}
    >
      {label ? <FormGroupLabel>{label}</FormGroupLabel> : ""}
      <div className="form-group__input">
        <input
          type={type}
          className={classNames("form-control", {
            "form-control--gray": variant === "gray",
            "form-control--default": !variant || variant === "default"
          })}
          value={value}
          required={required}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
        />
        {appendix || ""}
      </div>
    </FormGroup>
  );
};
