import React from "react";

import classNames from "classnames";

interface Props {
  variant: "success" | "danger" | "warning" | "info" | "primary";
  children: any;
}

export const Alert: React.FC<Props> = ({ children, variant }) => {
  return (
    <div className={classNames("alert", `alert--${variant}`)} role="alert">
      {children}
    </div>
  );
};
