import React from "react";

import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import whiteLogo from "../../../assets/images/icons/logo-white.png";
import layoutData from "../../../assets/layoutData.json";
import { Container } from "../Container";
import { FooterCol } from "./FooterCol";
import { FooterSubscribeCol } from "./FooterSubscribeCol";

export const Footer = () => {
  const { t } = useTranslation(["global"]);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <footer className="footer">
      <div className="footer__inner">
        <Container>
          <div className="row">
            <div className="col-lg-4 col-12">
              <FooterCol className="footer__col--logo">
                <a href={layoutData.logoLink}>
                  <img src={whiteLogo} alt="Print on Pack" />
                </a>
                <p>
                  <Trans t={t} i18nKey="layout.footer.tagline" components={[<strong />, <br />]} />
                </p>
              </FooterCol>
            </div>
            <div className="col-lg-4 col-12">
              <FooterCol title={t("layout.footer.get_in_touch")}>
                <ul className="footer__col__list">
                  <li>
                    <a href={`tel:${layoutData.footer.firstNumber.telAttribute}`}>
                      {layoutData.footer.firstNumber.text}
                    </a>
                  </li>
                  <li>
                    <a href={`tel:${layoutData.footer.secondNumber.telAttribute}`}>
                      {layoutData.footer.secondNumber.text}
                    </a>
                  </li>
                  <li>
                    <a href={`mailto:${layoutData.footer.mail}`}>{layoutData.footer.mail}</a>
                  </li>
                </ul>
              </FooterCol>
            </div>
            <div className="col-lg-4 col-12">
              <FooterSubscribeCol />
            </div>
          </div>
        </Container>

        <div className="footer__bottom">
          <Container>
            <div className="row flex-lg-row flex-column-reverse">
              <div className="col-12 col-lg-4">
                <p className="copyright">{t("layout.footer.copyright")}</p>
              </div>
              <div className="col-12 col-lg-8 link-block">
                <a href={layoutData.footer.becomePrintPartnerLink} target="_blank" rel="noreferrer">
                  {t("layout.footer.become_print_partner")}
                </a>
                <a href={layoutData.footer.privacyPolicyLink} target="_blank" rel="noreferrer">
                  {t("layout.footer.privacy_policy")}
                </a>
                {isMobile ? (
                  <a href={layoutData.footer.cookiePolicyLink} target="_blank" rel="noreferrer">
                    {t("layout.footer.cookie_policy")}
                  </a>
                ) : (
                  ""
                )}
                <a href={layoutData.footer.termsAndConditionsLink} target="_blank" rel="noreferrer">
                  {t("layout.footer.terms_and_conditions")}
                </a>
              </div>
            </div>
            {!isMobile ? (
              <div className="row link-block link-block--sub">
                <div className="col-12">
                  <a href={layoutData.footer.cookiePolicyLink} target="_blank" rel="noreferrer">
                    {t("layout.footer.cookie_policy")}
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}
          </Container>
        </div>
      </div>
    </footer>
  );
};
