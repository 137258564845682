import React from "react";

import { useTranslation } from "react-i18next";

import { Button } from "../../../Button/Button";

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

export const NextStepButton: React.FC<Props> = ({ disabled, onClick }) => {
  const { t } = useTranslation(["global"]);

  return (
    <div className="product-step__options__next-button">
      <Button
        variant="orange"
        disabled={disabled}
        onClick={() => {
          onClick();
        }}
      >
        <span className="font-padding">{t("wizard.next_step")}</span>
      </Button>
    </div>
  );
};
