import React from "react";

import ReactDOM from "react-dom/client";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles/style.scss";
import { Provider } from "react-redux";

import App from "./App";
import localization from "./i18n/config";
import reportWebVitals from "./reportWebVitals";
import CookiesService from "./services/CookiesService";
import { store } from "./store/store";

const init = () => {
  if (CookiesService.get("lng")) {
    localization.changeLanguage(CookiesService.get("lng")).then(() => null);
  } else {
    CookiesService.set("lng", localization.language);
  }
};

init();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
