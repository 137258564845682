import React, { useCallback, useRef } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ApiService } from "../../../../services/ApiService";
import { selectSpecificStepDataValue } from "../../../../store/wizard/wizardSlice";
import { IQuotationUpload } from "../../../../types/api-service";
import { WizardStepType } from "../../../../types/wizard";
import { Button } from "../../../Button/Button";
import { Icon } from "../../../common/Icon";

interface Props {
  quotationId: string;
  onFileUpload: (uploads: IQuotationUpload[]) => void;
}

export const DesignOrderDataDesignActions: React.FC<Props> = ({ quotationId, onFileUpload }) => {
  const savedVatNr = useSelector(selectSpecificStepDataValue(WizardStepType.Order, "options", "vatNr"));
  const inputRef = useRef<any>(null);
  const { t } = useTranslation(["global"]);

  const handleClick = useCallback(() => {
    if (!inputRef.current) return;
    inputRef.current.click();
  }, [inputRef]);

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileArr = event.target.files ? Array.from(event.target.files) : null;
      if (!fileArr || !fileArr.length) {
        return;
      }

      if (event.target) {
        // @ts-ignore
        event.target.value = null;
      }

      const formData = new FormData();

      for (let i = 0; i < fileArr.length; i++) {
        formData.append("file[]", fileArr[i]);
      }

      ApiService.uploadDesignToOrder(quotationId, formData).then((data) => {
        onFileUpload(data);
      });
    },
    [quotationId, onFileUpload]
  );

  return (
    <div className="order-step__data__design-actions">
      <p>{t("wizard.steps.design.files_upload_notice")}</p>

      <div className="order-step__data__design-actions__buttons">
        <input
          style={{ display: "none" }}
          ref={inputRef}
          type="file"
          multiple
          accept="application/pdf"
          onChange={handleFileChange}
        />
        <Button size="lg" variant="green" onClick={handleClick} className="btn-with-arrow-icon text-uppercase">
          <span className="font-padding">{t("wizard.steps.design.upload_design")}</span>
          <Icon name="arrow-right" />
        </Button>
        <Button
          size="lg"
          onClick={() => {
            ApiService.getFinishOrderRedirectUrl(
              quotationId,
              window.localStorage.getItem("orderToken") as string,
              savedVatNr
            ).then((data) => {
              window.location.href = data.data.url;
            });
          }}
        >
          <span className="font-padding">{t("wizard.steps.design.dont_have_design_will_upload_later")}</span>
        </Button>
      </div>
    </div>
  );
};
