import { instanceOfQuotation, IQuotation } from "../types/api-service";
import { ProductOptionTab } from "../types/product-step";
import { WizardStepType } from "../types/wizard";
import { WizardStepsData } from "../types/wizard-state";
import { WizardDataHelper } from "./wizardDataHelper";

class CarouselImageAssembler {
  static IMAGE_BASE_PATH = "/assets/products";

  static IMAGE_BASE_SIZE = "600x600";

  static images = {
    doypack: {
      step_0: [
        "1_5_Craft_Label_Snack.jpg",
        "DoyPack_2_Coffee_Printed.jpg",
        "DoyPack_2_Tea_Printed.jpg",
        "DoyPack_Label_Black_rounded_zipper.jpg",
        "DoyPack_Printed_easy_euro.jpg",
        "DoyPack_Stock_Craft_rounded_zipper.jpg"
      ]
    },
    gusset: {
      step_0: [
        "Gusset_Printed_nooptions.jpg",
        "1_4_Printed_Coffee.jpg",
        "1_1_Printed_Dog-food.jpg",
        "1_4_Printed_Snack.jpg",
        "1_2_Printed_Socks.jpg",
        "1_3_Printed_Tea.jpg"
      ]
    },
    flat_bottom: {
      step_0: [
        "1_4_Craft_Label_Coffee.jpg",
        "1_4_Printed_Coffee.jpg",
        "Flat_Bottom_Printed_valve.jpg",
        "Flat_Bottom_Label_Black_valve.jpg",
        "1_5_White_Label_Snack.jpg",
        "Flat_Bottom_Stock_Craft_nooptions.jpg"
      ]
    },
    sample: {
      step_0: [
          "sample-set.png"
      ]
    },
    stickers: {
      step_0: [
          "Roll1_1_dog.jpg",
          "Roll1_2_socks.jpg",
          "Roll1_3_tea.jpg",
          "Roll1_4_coffee.jpg",
          "Roll1_5_snack.jpg",
          "Roll1_6_Your_design.jpg",
      ]
    },
    products: {
      doypack: {
        step_1_label: {
          Black: [
            "DoyPack_Label_Black_Roll1_4_coffee.jpg",
            "DoyPack_Label_Black_Roll1_3_tea.jpg",
            "DoyPack_Label_Black_Roll1_1_dog.jpg",
            "DoyPack_Label_Black_Roll1_2_socks.jpg",
            "DoyPack_Label_Black_Roll1_5_snack.jpg"
          ],
          Craft: [
            "DoyPack_Label_Craft_Roll1_4_coffee.jpg",
            "DoyPack_Label_Craft_Roll1_3_tea.jpg",
            "DoyPack_Label_Craft_Roll1_1_dog.jpg",
            "DoyPack_Label_Craft_Roll1_2_socks.jpg",
            "DoyPack_Label_Craft_Roll1_5_snack.jpg"
          ],
          White: [
            "DoyPack_Label_White_Roll1_4_coffee.jpg",
            "DoyPack_Label_White_Roll1_3_tea.jpg",
            "DoyPack_Label_White_Roll1_1_dog.jpg",
            "DoyPack_Label_White_Roll1_2_socks.jpg",
            "DoyPack_Label_White_Roll1_5_snack.jpg"
          ]
        },
        step_1_label_2: {
          Black: [
            "DoyPack_Label_Black_Rolls2_4_coffee.jpg",
            "DoyPack_Label_Black_Rolls2_3_tea.jpg",
            "DoyPack_Label_Black_Rolls2_1_dog.jpg",
            "DoyPack_Label_Black_Rolls2_2_socks.jpg",
            "DoyPack_Label_Black_Rolls2_5_snack.jpg"
          ],
          Craft: [
            "DoyPack_Label_Craft_Rolls2_4_coffee.jpg",
            "DoyPack_Label_Craft_Rolls2_3_tea.jpg",
            "DoyPack_Label_Craft_Rolls2_1_dog.jpg",
            "DoyPack_Label_Craft_Rolls2_2_socks.jpg",
            "DoyPack_Label_Craft_Rolls2_5_snack.jpg"
          ],
          White: [
            "DoyPack_Label_White_Rolls2_4_coffee.jpg",
            "DoyPack_Label_White_Rolls2_3_tea.jpg",
            "DoyPack_Label_White_Rolls2_1_dog.jpg",
            "DoyPack_Label_White_Rolls2_2_socks.jpg",
            "DoyPack_Label_White_Rolls2_5_snack.jpg"
          ]
        },
        step_1_stock: [
          "DoyPack_Stock_Black_rounded_zipper.jpg",
          "DoyPack_Stock_Craft_rounded_zipper.jpg",
          "DoyPack_Stock_White_rounded_zipper.jpg"
        ],
        step_1_printed: [
          "DoyPack_Printed_rounded_zipper.jpg",
          "DoyPack_2_Coffee_Printed.jpg",
          "DoyPack_2_Dog-food_Printed.jpg",
          "DoyPack_2_Snack_Printed.jpg",
          "DoyPack_2_Socks_Printed.jpg",
          "DoyPack_2_Tea_Printed.jpg"
        ],
        step_1_screen: {
          Black: [
            "DoyPack_Black_1_your-logo.jpg",
            "DoyPack_Black_2_coffee.jpg",
            "DoyPack_Black_3_tea.jpg",
            "DoyPack_Black_4_dog.jpg",
            "DoyPack_Black_5_socks.jpg",
            "DoyPack_Black_6_snack.jpg",
            "DoyPack_Black_7_POP-logo.jpg",
            "DoyPack_Black_8_P-symbol.jpg"
          ],
          Craft: [
            "DoyPack_Craft_1_your-logo.jpg",
            "DoyPack_Craft_2_coffee.jpg",
            "DoyPack_Craft_3_tea.jpg",
            "DoyPack_Craft_4_dog.jpg",
            "DoyPack_Craft_5_socks.jpg",
            "DoyPack_Craft_6_snack.jpg",
            "DoyPack_Craft_7_POP-logo.jpg",
            "DoyPack_Craft_8_P-symbol.jpg"
          ],
          White: [
            "DoyPack_White_1_your-logo.jpg",
            "DoyPack_White_2_coffee.jpg",
            "DoyPack_White_3_tea.jpg",
            "DoyPack_White_4_dog.jpg",
            "DoyPack_White_5_socks.jpg",
            "DoyPack_White_6_snack.jpg",
            "DoyPack_White_7_POP-logo.jpg",
            "DoyPack_White_8_P-symbol.jpg"
          ]
        }
      },
      gusset: {
        step_1_label: {
          Black: [],
          Craft: [],
          White: []
        },
        step_1_label_2: {
          Black: [],
          Craft: [],
          White: []
        },
        step_1_stock: [],
        step_1_printed: [
          "Gusset_Printed_nooptions.jpg",
          "1_4_Printed_Coffee.jpg",
          "1_1_Printed_Dog-food.jpg",
          "1_4_Printed_Snack.jpg",
          "1_2_Printed_Socks.jpg",
          "1_3_Printed_Tea.jpg"
        ],
        step_1_screen: {
          Black: [],
          Craft: [],
          White: []
        }
      },
      flat_bottom: {
        step_1_label: {
          Black: [
            "FlatBottom_Label_Black_Roll1_4_coffee.jpg",
            "FlatBottom_Label_Black_Roll1_3_tea.jpg",
            "FlatBottom_Label_Black_Roll1_1_dog.jpg",
            "FlatBottom_Label_Black_Roll1_2_socks.jpg",
            "FlatBottom_Label_Black_Roll1_5_snack.jpg"
          ],
          Craft: [
            "FlatBottom_Label_Craft_Roll1_4_coffee.jpg",
            "FlatBottom_Label_Craft_Roll1_3_tea.jpg",
            "FlatBottom_Label_Craft_Roll1_1_dog.jpg",
            "FlatBottom_Label_Craft_Roll1_2_socks.jpg",
            "FlatBottom_Label_Craft_Roll1_5_snack.jpg"
          ],
          White: [
            "FlatBottom_Label_White_Roll1_4_coffee.jpg",
            "FlatBottom_Label_White_Roll1_3_tea.jpg",
            "FlatBottom_Label_White_Roll1_1_dog.jpg",
            "FlatBottom_Label_White_Roll1_2_socks.jpg",
            "FlatBottom_Label_White_Roll1_5_snack.jpg"
          ]
        },
        step_1_label_2: {
          Black: [
            "FlatBottom_Label_Black_Rolls2_4_coffee.jpg",
            "FlatBottom_Label_Black_Rolls2_3_tea.jpg",
            "FlatBottom_Label_Black_Rolls2_1_dog.jpg",
            "FlatBottom_Label_Black_Rolls2_2_socks.jpg",
            "FlatBottom_Label_Black_Rolls2_5_snack.jpg"
          ],
          Craft: [
            "FlatBottom_Label_Craft_Rolls2_4_coffee.jpg",
            "FlatBottom_Label_Craft_Rolls2_3_tea.jpg",
            "FlatBottom_Label_Craft_Rolls2_1_dog.jpg",
            "FlatBottom_Label_Craft_Rolls2_2_socks.jpg",
            "FlatBottom_Label_Craft_Rolls2_5_snack.jpg"
          ],
          White: [
            "FlatBottom_Label_White_Rolls2_4_coffee.jpg",
            "FlatBottom_Label_White_Rolls2_3_tea.jpg",
            "FlatBottom_Label_White_Rolls2_1_dog.jpg",
            "FlatBottom_Label_White_Rolls2_2_socks.jpg",
            "FlatBottom_Label_White_Rolls2_5_snack.jpg"
          ]
        },
        step_1_stock: [
          "Flat_Bottom_Stock_Black_valve.jpg",
          "Flat_Bottom_Stock_Craft_nooptions.jpg",
          "Flat_Bottom_Stock_White_nooptions.jpg"
        ],
        step_1_printed: [
          "1_4_Printed_Coffee.jpg",
          "1_3_Printed_Tea.jpg",
          "1_1_Printed_Dog-food.jpg",
          "1_2_Printed_Socks.jpg",
          "1_5_Printed_Snack.jpg",
          "Flat_Bottom_Printed_valve.jpg"
        ],
        step_1_screen: {
          Black: [
            "FlatBottom_Black_1_your-logo.jpg",
            "FlatBottom_Black_2_coffee.jpg",
            "FlatBottom_Black_3_tea.jpg",
            "FlatBottom_Black_4_dog.jpg",
            "FlatBottom_Black_5_socks.jpg",
            "FlatBottom_Black_6_snack.jpg",
            "FlatBottom_Black_7_POP-logo.jpg",
            "FlatBottom_Black_8_P-symbol.jpg"
          ],
          Craft: [
            "FlatBottom_Craft_1_your-logo.jpg",
            "FlatBottom_Craft_2_coffee.jpg",
            "FlatBottom_Craft_3_tea.jpg",
            "FlatBottom_Craft_4_dog.jpg",
            "FlatBottom_Craft_5_socks.jpg",
            "FlatBottom_Craft_6_snack.jpg",
            "FlatBottom_Craft_7_POP-logo.jpg",
            "FlatBottom_Craft_8_P-symbol.jpg"
          ],
          White: [
            "FlatBottom_White_1_your-logo.jpg",
            "FlatBottom_White_2_coffee.jpg",
            "FlatBottom_White_3_tea.jpg",
            "FlatBottom_White_4_dog.jpg",
            "FlatBottom_White_5_socks.jpg",
            "FlatBottom_White_6_snack.jpg",
            "FlatBottom_White_7_POP-logo.jpg",
            "FlatBottom_White_8_P-symbol.jpg"
          ]
        }
      },
      stickers: {
        step_1_label: {
          Black: [],
          Craft: [],
          White: []
        },
        step_1_label_2: {
          Black: [],
          Craft: [],
          White: []
        },
        step_1_screen: {
          Black: [],
          Craft: [],
          White: []
        },
        step_1_stock: [],
        step_1_printed: [],
        step_1_stickers: [
            "Roll1_1_dog.jpg",
            "Roll1_2_socks.jpg",
            "Roll1_3_tea.jpg",
            "Roll1_4_coffee.jpg",
            "Roll1_5_snack.jpg",
            "Roll1_6_Your_design.jpg",
        ]
      }
    }
  };

  private getProductStepImages(stepData: WizardStepsData[WizardStepType.Product] | IQuotation): {
    images: {
      path: string;
      isActive: boolean;
    }[];
    path: string;
    shouldMoveCarousel?: boolean;
  } {
    const productCode = WizardDataHelper.getProductTypeCodeFromData(stepData);
    const productType = WizardDataHelper.getProductType(productCode);

    const packagingType = WizardDataHelper.getPackagingTypeCodeFromData(stepData);

    if (!productCode || !packagingType) {
      return {
        images: CarouselImageAssembler.images[productType as "doypack" | "flat_bottom" | "gusset" | "sample"| "stickers"].step_0.map(
          (e) => ({
            path: e,
            isActive: false
          })
        ),
        path: `step_0/${productType}/${CarouselImageAssembler.IMAGE_BASE_SIZE}`
      };
    }
    const imagesForStep = CarouselImageAssembler.images.products[productType as "doypack" | "flat_bottom" | "gusset"| "stickers"];
    const packagingTypeCode = WizardDataHelper.getPackagingTypeCodeValue(packagingType);

    const materialVariationCode = WizardDataHelper.getMaterialCode(
      WizardDataHelper.getMaterialTypeCodeFromData(stepData) || "material_variation.black"
    );

    if (packagingTypeCode === "label") {
      return {
        shouldMoveCarousel: false,
        images: imagesForStep.step_1_label[materialVariationCode].map((e) => ({
          path: e,
          isActive: false
        })),
        path: `products/${productType}/step_1_label/${CarouselImageAssembler.IMAGE_BASE_SIZE}/${materialVariationCode}`
      };
    }

    if (packagingTypeCode === "label_2") {
      return {
        shouldMoveCarousel: false,
        images: imagesForStep.step_1_label_2[materialVariationCode].map((e) => ({
          path: e,
          isActive: false
        })),
        path: `products/${productType}/step_1_label_2/${CarouselImageAssembler.IMAGE_BASE_SIZE}/${materialVariationCode}`
      };
    }

    if (packagingTypeCode === "screen") {
      return {
        shouldMoveCarousel: false,
        images: imagesForStep.step_1_screen[materialVariationCode].map((e) => ({
          path: e,
          isActive: false
        })),
        path: `products/${productType}/step_1_screen/${CarouselImageAssembler.IMAGE_BASE_SIZE}/${materialVariationCode}`
      };
    }

    if (packagingTypeCode === "stock") {
      return {
        images: imagesForStep[`step_1_${packagingTypeCode}`].map((e) => ({
          path: e,
          isActive: e.includes(materialVariationCode)
        })),
        path: `products/${productType}/step_1_${packagingTypeCode}/${CarouselImageAssembler.IMAGE_BASE_SIZE}`
      };
    }

    return {
      images: imagesForStep[`step_1_${packagingTypeCode}`].map((e) => ({
        path: e,
        isActive: false
      })),
      path: `products/${productType}/step_1_${packagingTypeCode}/${CarouselImageAssembler.IMAGE_BASE_SIZE}`
    };
  }

  assembleSizes = (data: WizardStepsData[WizardStepType.Product] | IQuotation, showSizesOverwrite?: boolean) => {
    let showSizes = instanceOfQuotation(data)
      ? false
      : data.values.activeOptionTab === ProductOptionTab.Size ||
        data.values.activeOptionTab === ProductOptionTab.Options;

    if (typeof showSizesOverwrite !== "undefined") {
      showSizes = showSizesOverwrite;
    }

    const sizeData = WizardDataHelper.getSizeDataFromData(data);

    if (showSizes && sizeData) {
      return {
        sizes: sizeData as [number, number, number, number, number]
      };
    }

    return {};
  };

  assembleCarouselImageList(
    data: WizardStepsData[WizardStepType.Product] | IQuotation,
    showSizesOverwrite?: boolean
  ): {
    images: {
      path: string;
      isActive: boolean;
    }[];
    sizes?: [number, number, number, number, number];
    shouldMoveCarousel: boolean;
  } {
    const { images, path, shouldMoveCarousel } = this.getProductStepImages(data);

    return {
      images: images.map((e) => ({
        path: `${CarouselImageAssembler.IMAGE_BASE_PATH}/${path}/${e.path}`,
        isActive: e.isActive
      })),
      shouldMoveCarousel: typeof shouldMoveCarousel === "undefined" ? true : shouldMoveCarousel,
      ...this.assembleSizes(data, showSizesOverwrite)
    };
  }
}

export default new CarouselImageAssembler();
