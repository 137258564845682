import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  selectProductMaterialsBasedOnSelectedType,
  selectSpecificStepDataValue,
  setSpecificStepDataValue
} from "../../../../store/wizard/wizardSlice";
import { WizardStepType } from "../../../../types/wizard";
import { getTranslationFromLangObj } from "../../../../utilities/language";
import { RadioButtons } from "../../../FormGroup/RadioButtons/RadioButtons";
import { ProductOptionContent } from "./shared/ProductOptionContent";
import { ProductOptionContentBody } from "./shared/ProductOptionContentBody";
import { ProductOptionDescription } from "./shared/ProductOptionDescription";

interface Props {
  onSelect: () => void;
}

export const ProductOptionsMaterialSelector: React.FC<Props & { ref: React.Ref<HTMLDivElement> }> = React.forwardRef(
  ({ onSelect }, ref: React.Ref<HTMLDivElement>) => {
    const { t } = useTranslation(["global"]);

    const SelectedProductTypeCode = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "typeCode"));
    const selectedMaterial = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "material"));
    const selectedPackagingTypeCode = useSelector(
      selectSpecificStepDataValue(WizardStepType.Product, "options", "typeCode")
    );
    const materials = useSelector(selectProductMaterialsBasedOnSelectedType);

    const dispatch = useDispatch();

    const handleChange = useCallback(
      (value: string) => {
        dispatch(
          setSpecificStepDataValue({
            type: WizardStepType.Product,
            option: "options",
            subOption: "material",
            value
          })
        );
        if (selectedPackagingTypeCode === "packaging_type.printed") {
          dispatch(
            setSpecificStepDataValue({
              type: WizardStepType.Product,
              option: "options",
              subOption: "options",
              value: []
            })
          );
        }

        onSelect();
      },
      [onSelect, selectedPackagingTypeCode]
    );

    return (
      <ProductOptionContent ref={ref}>
        <ProductOptionDescription>
          {t(
            `wizard.steps.product.material_tab.${
              SelectedProductTypeCode.length ? SelectedProductTypeCode : "default"
            }.description`
          )}
        </ProductOptionDescription>
        <ProductOptionContentBody>
          <RadioButtons
            itemSeparator
            name="option_material"
            items={[
              ...materials.map((material) => ({
                value: material.uuid,
                text: getTranslationFromLangObj(material.nameTrans),
                subText: <>{getTranslationFromLangObj(material.shortDescriptionTrans)}</>,
                tooltip: getTranslationFromLangObj(material.descriptionTrans)
              }))
            ]}
            value={selectedMaterial}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
          />
        </ProductOptionContentBody>
      </ProductOptionContent>
    );
  }
);
