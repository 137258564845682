import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { WizardStepStatus, WizardStepType } from "../../../types/wizard";
import { WizardStep } from "./WizardStep";

interface Props {
  progressData: {
    [key in WizardStepType]: WizardStepStatus;
  };
}

export const WizardSteps: React.FC<Props> = ({ progressData }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const { t } = useTranslation(["global"]);

  const steps = useMemo(() => {
    return [
      {
        type: WizardStepType.Product,
        keyValue: 1,
        title: t("wizard.steps.product.title")
      },
      {
        type: WizardStepType.Order,
        keyValue: 2,
        title: t("wizard.steps.order.title")
      },
      {
        type: WizardStepType.Design,
        keyValue: 3,
        title: t("wizard.steps.design.title")
      }
    ];
  }, [t]);

  return (
    <div className="wizard__steps">
      <div className="row w-100">
        {steps
          .filter((e) => (isMobile ? progressData[e.type] === WizardStepStatus.InProgress : true))
          .map((step, index) => {
            const status = progressData[step.type];
            return (
              <WizardStep
                colSize={isMobile ? 12 : status === WizardStepStatus.InProgress ? 8 : 2}
                state={status}
                keyValue={step.keyValue}
                title={step.title}
                key={index}
              />
            );
          })}
      </div>
    </div>
  );
};
