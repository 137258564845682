import React, { useCallback, useEffect, useMemo, useState } from "react";

import axios from "axios";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useModal from "../../../../hooks/useModal";
import { useSafeForLaterControls } from "../../../../hooks/useSafeForLaterControls";
import { ApiService } from "../../../../services/ApiService";
import { LocalStorageService } from "../../../../services/LocalStorageService";
import {
  selectSpecificStepDataValue,
  selectWizardQuotationData,
  setSpecificStepDataValue
} from "../../../../store/wizard/wizardSlice";
import { IOrderSummary } from "../../../../types/api-service";
import { WizardStepType } from "../../../../types/wizard";
import { Button } from "../../../Button/Button";
import { Icon } from "../../../common/Icon";
import Loader from "../../../common/Loader";
import { Modal } from "../../../Modal/Modal";
import { BlockTitle } from "../shared/BlockTitle";
import { OrderSummaryBlock } from "../shared/OrderSummaryBlock";
import { PaymentModalBody } from "./PaymentModalBody";

export const OrderSummary = () => {
  const { show, toggle } = useModal();
  const { t } = useTranslation(["global"]);

  const selectedVatNr = useSelector(selectSpecificStepDataValue(WizardStepType.Order, "options", "vatNr"));
  const selectedCountry = useSelector(selectSpecificStepDataValue(WizardStepType.Order, "options", "countryCode"));
  const { data: quotationData } = useSelector(selectWizardQuotationData);
  const { getSaveForLaterRedirectUri, loader: saveForLaterLoader } = useSafeForLaterControls();
  const [orderSummary, setOrderSummary] = useState<IOrderSummary | null>(null);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const isDisabled = useMemo(() => {
    return !selectedCountry;
  }, [selectedCountry]);

  const getSummaryData = useCallback(async () => {
    if (!quotationData || !selectedCountry) return;
    setLoader(true);
    try {
      const data = await ApiService.getOrderSummary(quotationData.uuid, selectedCountry, selectedVatNr);
      setOrderSummary(data);

      setLoader(false);
    } catch (e) {
      if (axios.isAxiosError(e) && e.request) {
        switch (e.request.status) {
          case 401:
            LocalStorageService.removeTokens();
            window.location.reload();
            break;
          case 406:
            dispatch(
              setSpecificStepDataValue({
                type: WizardStepType.Order,
                option: "options",
                subOption: "countryCode",
                value: ""
              })
            );
            break;
          case 400:
            LocalStorageService.reset();
            window.location.reload();
            break;
          default:
        }

        setLoader(false);
      }
      console.error(e);
    }
  }, [quotationData, selectedCountry, selectedVatNr, dispatch]);

  useEffect(() => {
    getSummaryData().then(() => null);
  }, [quotationData, selectedCountry, selectedVatNr]);

  return (
    <div
      className={classNames("order-step__summary", "border-top-to-md", {
        "disabled-block": isDisabled
      })}
    >
      <Loader show={loader} />
      <BlockTitle title={t("wizard.steps.order.summary")} />
      <OrderSummaryBlock
        total={orderSummary?.totalSum}
        shipping={orderSummary?.deliveryPrice}
        vat={orderSummary?.vat}
        vatPerc={orderSummary?.vatRate}
        totalToPay={orderSummary?.totalSumToPay}
      />
      <div className="order-step__summary__actions">
        <Button
          className="pay-action btn-with-arrow-icon"
          size="lg"
          onClick={() => {
            toggle();
          }}
          variant="orange"
        >
          <span className="font-padding">{t("wizard.steps.order.pay")}</span>
          <Icon name="arrow-right" />
        </Button>
        <Modal show={show} className="how-to-order-modal" onHide={toggle}>
          <PaymentModalBody data={quotationData} country={selectedCountry} vatNr={selectedVatNr} />
        </Modal>
        <Button
          className="mt-1"
          onClick={async () => {
            if (!quotationData) return;
            getSaveForLaterRedirectUri(quotationData.uuid, quotationData.orderToken, selectedVatNr);
          }}
          variant="gray"
        >
          <Loader show={saveForLaterLoader} variant="small" />
          <span className="font-padding">{t("wizard.steps.order.save_order_for_later")}</span>
        </Button>
      </div>
    </div>
  );
};
