export const getAuthHeader = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem("token")}`
  };
};
export const getOrderTokenHeader = (orderToken: string | null = null) => {
  return {
    ordertokenauth: `${orderToken ?? localStorage.getItem("orderToken")}`
  };
};
