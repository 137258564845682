import { useState } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ApiService } from "../services/ApiService";
import { setSpecificStepDataValue } from "../store/wizard/wizardSlice";
import { WizardStepType } from "../types/wizard";

export const useCustomerRegisterControls = () => {
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submitCustomerRegisterForm = async (
    quotationId: string,
    orderToken: string,
    email: string,
    phone: string,
    vatNr: string | null,
    country: string,
    name: string
  ) => {
    setLoader(true);
    try {
      await ApiService.submitCustomerRegisterForm(email, phone, vatNr, country, name, quotationId, orderToken).then(
        (data) => {
          console.log(data, data.customerVat);
          if (data.customerVat) {
            dispatch(
              setSpecificStepDataValue({
                type: WizardStepType.Order,
                option: "options",
                subOption: "vatNr",
                value: data.customerVat
              })
            );
          }
          navigate(`/design/${quotationId}?token=${orderToken}`);
        }
      );
    } catch (e) {
      setLoader(false);
      throw e;
    }
  };

  return {
    submitCustomerRegisterForm,
    loader
  };
};
