import React from "react";

import { WizardProductPage, WizardOrderPage, WizardDesignPage, PaymentSuccess, SaveToken } from "../pages";
import { PaymentFailed } from "../pages/PaymentFailed";
import { SampleOrder } from "../pages/SampleOrder";
import { WizardCustomerRegisterPage } from "../pages/WizardCustomerRegisterPage";

interface RouteGroup {
  // layoutElement: React.ReactElement;
  path: string;
  items: Route[];
}

interface Route {
  path?: string;
  element: React.ReactElement;
  isIndex?: boolean;
}

export const routes: RouteGroup[] = [
  {
    path: "/",
    items: [
      {
        element: <WizardProductPage />,
        isIndex: true
      },
      {
        element: <WizardOrderPage />,
        path: "/order"
      },
      {
        element: <WizardCustomerRegisterPage />,
        path: "/register"
      },
      {
        element: <WizardDesignPage />,
        path: "/design/:orderId"
      },
      {
        element: <WizardDesignPage />,
        path: "/design"
      },
      {
        element: <PaymentSuccess />,
        path: "/payment/success/:paymentId"
      },
      {
        element: <SampleOrder />,
        path: "/sample"
      },
      {
        element: <PaymentFailed />,
        path: "/payment/fail"
      },
      {
        element: <SaveToken />,
        path: "/save-token/:token"
      }
    ]
  }
];
