import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";

import { ReactComponent as InfoIconSvg } from "./assets/images/icons/info-icon.svg";
import Loader from "./components/common/Loader";
import { Layout } from "./components/Layout/Layout";
import { useAppDispatch } from "./hooks/redux";
import { Router } from "./router/Router";
import { fetchConstructorData, selectWizardApiDataLoader } from "./store/wizard/wizardSlice";

function App() {
  const dispatch = useAppDispatch();

  const apiDataLoader = useSelector(selectWizardApiDataLoader);

  useEffect(() => {
    if (apiDataLoader) {
      document.body.classList.add("prevent-scroll");
    } else {
      document.body.classList.remove("prevent-scroll");
    }
  }, [apiDataLoader]);

  useEffect(() => {
    dispatch(fetchConstructorData());
  }, []);

  return (
    <div className="wrapper">
      <Loader show={apiDataLoader} fullBlind />
      <Layout>
        <Router />
      </Layout>
      <ReactTooltip place="top" type="dark" effect="solid" className="green-tooltip" delayShow={200} />

      <ReactTooltip
        id="with-info-icon"
        place="top"
        type="dark"
        effect="solid"
        className="green-tooltip"
        delayShow={200}
        getContent={(dataTip) => (
          <>
            <div className="info-icon">
              <InfoIconSvg />
            </div>
            <p>{dataTip}</p>
          </>
        )}
      />
      <ToastContainer />
    </div>
  );
}

export default App;
