import React, { useMemo, useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useCustomerRegisterControls } from "../../../../hooks/useCustomerRegisterControls";
import { usePaymentControls } from "../../../../hooks/usePaymentControls";
import { selectSpecificStepDataValue, selectWizardQuotationData } from "../../../../store/wizard/wizardSlice";
import { WizardStepType } from "../../../../types/wizard";
import { Button } from "../../../Button/Button";
import { Icon } from "../../../common/Icon";
import Loader from "../../../common/Loader";
import { InputField } from "../../../FormGroup/InputField/InputField";
import { BlockTitle } from "../shared/BlockTitle";

export const SubmitRegister = () => {
  const { t } = useTranslation(["global"]);
  const selectedVatNr = useSelector(selectSpecificStepDataValue(WizardStepType.Order, "options", "vatNr"));
  const selectedCountry = useSelector(selectSpecificStepDataValue(WizardStepType.Order, "options", "countryCode"));
  const { data: quotationData } = useSelector(selectWizardQuotationData);
  const { submitCustomerRegisterForm, loader: registerLoader } = useCustomerRegisterControls();
  const { getAndSaveOrderToken, loader: paymentLoader } = usePaymentControls();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");

  const isDisabled = useMemo(() => {
    return !email || !phone || !name;
  }, [email, phone, name]);

  return (
    <div className="order-step__summary border-top-to-md">
      <BlockTitle title={t("wizard.steps.order.form.register")} />
      <div className="mt-3">
        <div className="position-relative">
          <InputField
            label={<span className="d-flex align-items-center">{t("wizard.steps.order.form.name")}</span>}
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="mt-1">
        <div className="position-relative">
          <InputField
            label={<span className="d-flex align-items-center">{t("wizard.steps.order.form.email")}</span>}
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="mt-1">
        <div className="position-relative">
          <InputField
            label={<span className="d-flex align-items-center">{t("wizard.steps.order.form.phone")}</span>}
            type="phone"
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="order-step__summary__actions">
        <Button
          className={classNames("mt-1", "pay-action", "btn-with-arrow-icon", {
            "disabled-block": isDisabled
          })}
          onClick={async () => {
            if (!quotationData) return;
            await submitCustomerRegisterForm(
              quotationData.uuid,
              quotationData.orderToken,
              email,
              phone,
              selectedVatNr,
              selectedCountry,
              name
            );
          }}
          variant="orange"
        >
          <Loader show={registerLoader} variant="small" />
          <span className="font-padding">{t("wizard.steps.order.form.submit")}</span>
          <Icon name="arrow-right" />
        </Button>
        <Button
          className="mt-1"
          size="lg"
          onClick={() => {
            if (!quotationData) return;
            getAndSaveOrderToken(quotationData.uuid, selectedCountry, selectedVatNr);
          }}
          variant="grey"
        >
          <Loader show={paymentLoader} variant="small" />
          <span className="font-padding">{t("wizard.steps.order.pay")}</span>
        </Button>
      </div>
    </div>
  );
};
