import { useState } from "react";

import axios from "axios";
import { useDispatch } from "react-redux";

import { ApiService } from "../services/ApiService";
import { LocalStorageService } from "../services/LocalStorageService";
import { resetState, setSpecificStepDataValue } from "../store/wizard/wizardSlice";
import { WizardStepType } from "../types/wizard";

export const usePaymentControls = () => {
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const getAndSaveOrderToken = async (quotationId: string, selectedCountry: string, selectedVatNr: string | null) => {
    setLoader(true);
    try {
      await ApiService.getOrderToken(quotationId, selectedCountry, selectedVatNr).then((data) => {
        LocalStorageService.saveOrderToken(data.token);
        dispatch(
          setSpecificStepDataValue({
            type: WizardStepType.Order,
            option: "options",
            subOption: "orderToken",
            value: data.token
          })
        );
        window.location.replace(data.stripe.url);
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        switch (e.request.status) {
          case 401:
            LocalStorageService.removeTokens();
            break;
          case 400:
            LocalStorageService.reset();
            dispatch(resetState());
            break;
          default:
        }
        window.location.reload();
      }
      throw e;
    }
  };

  return {
    getAndSaveOrderToken,
    loader
  };
};
