import React from "react";

import classNames from "classnames";
import { useSelector } from "react-redux";

import { selectWizardProgress } from "../../store/wizard/wizardSlice";
import { WizardSteps } from "./WizardSteps/WizardSteps";

interface Props {
  children: any;
}

export const Wizard: React.FC<Props> = ({ children }) => {
  const progressData = useSelector(selectWizardProgress);

  return (
    <div className={classNames("wizard")}>
      <WizardSteps progressData={progressData} />

      <div className="wizard__content">{children}</div>
    </div>
  );
};
