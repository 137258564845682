import { useCallback, useState } from "react";

const useModal = () => {
  const [show, setIsShow] = useState(false);

  const toggle = useCallback(() => {
    setIsShow(!show);
  }, [show]);

  return {
    show,
    toggle
  };
};

export default useModal;
