import React, { useState } from "react";

import { useMediaQuery } from "react-responsive";

import { BackButton } from "../components/Wizard/StepComponents/Order/BackButton";
import { OrderData } from "../components/Wizard/StepComponents/Order/OrderData";
import { OrderImagePreview } from "../components/Wizard/StepComponents/Order/OrderImagePreview";
import { OrderSummary } from "../components/Wizard/StepComponents/Order/OrderSummary";
import { Wizard } from "../components/Wizard/Wizard";
import { WizardContent } from "../components/Wizard/WizardContent/WizardContent";
import { useWizardStepCheck } from "../hooks/useWizardStepCheck";

export const WizardOrderPage = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [showImageSizes, setImageShowSizes] = useState(false);

  useWizardStepCheck();

  return (
    <Wizard>
      <div className="product-step">
        <WizardContent>
          <div className="row">
            <div className="col-lg-4 col-12">
              <OrderImagePreview showSizesOverwrite={showImageSizes} />
            </div>
            <div className="col-lg-4 col-12">
              <OrderData
                onImageSizeShowChange={(val) => {
                  setImageShowSizes(val);
                }}
              />
            </div>
            <div className="col-lg-4 col-12">
              <OrderSummary />

              {isMobile ? (
                <div className="mt-3 mobile-back-button">
                  <BackButton />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </WizardContent>
      </div>
    </Wizard>
  );
};
