import React from "react";

import { Container } from "./Container";
import { Footer } from "./Footer/Footer";
import { Header } from "./Header/Header";

interface Props {
  children: any;
}
export const Layout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <Header />
      <div className="content">
        <Container>{children}</Container>
      </div>
      <Footer />
    </>
  );
};
