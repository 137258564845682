import React from "react";

import { ReactComponent as LogoSvg } from "../../../assets/images/icons/logo.svg";
import layoutData from "../../../assets/layoutData.json";
import { LanguageSelector } from "../../LanguageSelector/LanguageSelector";
import { Container } from "../Container";

export const Header = () => {
  return (
    <header className="header">
      <Container>
        <div className="header__inner">
          <div className="header__logo">
            <a href={layoutData.logoLink}>
              <LogoSvg />
            </a>
          </div>

          <div className="header__language">
            <LanguageSelector />
          </div>
        </div>
      </Container>
    </header>
  );
};
