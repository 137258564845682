import React, { useMemo } from "react";

import { useHasCustomDesign } from "../../../../hooks/useHasCustomDesign";
import { IQuotation, IQuotationUpload } from "../../../../types/api-service";
import { ISummaryData } from "../../../../types/wizard-state";
import { getPackageSizeName } from "../../../../utilities/formatter";
import { getTranslationFromLangObj } from "../../../../utilities/language";
import { OrderProductSummary } from "../shared/OrderProductSummary";
import { DesignOrderDataDesignActions } from "./DesignOrderDataDesignActions";
import { DesignOrderUploads } from "./DesignOrderUploads";

interface Props {
  quotationData: IQuotation;
  onUploadDelete: (id: string) => void;
  unUploadsUpdate: (upload: IQuotationUpload[]) => void;
  onImageSizeShowChange: (value: boolean) => void;
}

export const DesignOrderData: React.FC<Props> = ({
  onImageSizeShowChange,
  quotationData,
  onUploadDelete,
  unUploadsUpdate
}) => {
  const hasCustomDesign = useHasCustomDesign(quotationData.packagingType?.uuid);
  const summaryData: ISummaryData = useMemo(() => {
    return {
      ...(quotationData.material
        ? {
            material: {
              id: quotationData.material.uuid,
              name: getTranslationFromLangObj(quotationData.material.nameTrans)
            }
          }
        : {}),
      size: {
        id: quotationData.packageSize.uuid,
        name: getPackageSizeName(quotationData.packageSize)
      },
      options: quotationData.pouchOptions.map((e) => ({
        id: e.uuid,
        name: getTranslationFromLangObj(e.nameTrans)
      })),
      ...(quotationData.materialVariation
        ? {
            materialVariation: {
              id: quotationData.materialVariation.uuid,
              name: getTranslationFromLangObj(quotationData.materialVariation.nameTrans)
            }
          }
        : {}),

      ...(quotationData.packagingType
        ? {
            packagingType: {
              id: quotationData.packagingType.uuid,
              name: getTranslationFromLangObj(quotationData.packagingType.nameTrans)
            }
          }
        : {}),
      product: {
        id: quotationData.product.uuid,
        name: getTranslationFromLangObj(quotationData.product.nameTrans)
      },
      designAmount: quotationData.designAmount,
      quantity: quotationData.quantity
    };
  }, [quotationData]);

  return (
    <div className="order-step__data right-border">
      <OrderProductSummary
        summaryData={summaryData}
        designAmountValue={summaryData.designAmount}
        piecesCountValue={summaryData.quantity}
        unitPrice={quotationData.unitPrice}
        onSizeShow={onImageSizeShowChange}
      />
      {quotationData.uploads && hasCustomDesign ? (
        <DesignOrderUploads onUploadDelete={onUploadDelete} uploads={quotationData.uploads} />
      ) : (
        ""
      )}

      {hasCustomDesign ? (
        <DesignOrderDataDesignActions
          quotationId={quotationData.uuid}
          onFileUpload={(data) => {
            unUploadsUpdate(data);
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};
