export enum ProductOptionTab {
  Type,
  Material,
  Size,
  Options
}

// export enum ProductTypes {
//   Printed,
//   Label,
//   Stock
// }
