import React, { useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  selectPouchOptionsBasedOnSelectedProduct,
  selectSpecificStepDataValue,
  setSpecificStepDataValue
} from "../../../../store/wizard/wizardSlice";
import { WizardStepType } from "../../../../types/wizard";
import { getTranslationFromLangObj } from "../../../../utilities/language";
import { Checkbox } from "../../../FormGroup/Checkbox/ Checkbox";
import { CheckboxGroup } from "../../../FormGroup/Checkbox/CheckboxGroup";
import { ProductOptionContent } from "./shared/ProductOptionContent";
import { ProductOptionContentBody } from "./shared/ProductOptionContentBody";
import { ProductOptionDescription } from "./shared/ProductOptionDescription";

const getOptionImagePath = (rawCode: string) => {
  const code = rawCode.split("pouch_option.")[1];

  return `/assets/products/options/${code}.jpg`;
};

export const ProductOptionsSelector: React.FC<{ ref: React.Ref<HTMLDivElement> }> = React.forwardRef(
  (_, ref: React.Ref<HTMLDivElement>) => {
    const { t } = useTranslation(["global"]);
    const selectedOptions = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "options"));
    const options = useSelector(selectPouchOptionsBasedOnSelectedProduct);
    const dispatch = useDispatch();
    const handleChange = useCallback(
      (option: string) => {
        const newSelectedOptions = selectedOptions.includes(option)
          ? [...selectedOptions].filter((o) => o !== option)
          : [...selectedOptions, option];

        dispatch(
          setSpecificStepDataValue({
            type: WizardStepType.Product,
            option: "options",
            subOption: "options",
            value: newSelectedOptions as string[]
          })
        );
      },
      [selectedOptions]
    );
    return (
      <ProductOptionContent ref={ref}>
        <ProductOptionDescription>{t("wizard.steps.product.options_tab.description")}</ProductOptionDescription>
        <ProductOptionContentBody>
          <CheckboxGroup>
            {options !== null
              ? options.map(({ nameTrans, uuid, descriptionTrans, shortDescriptionTrans, code, isMandatory }, idx) => (
                  <Checkbox
                    key={idx}
                    value={selectedOptions.includes(uuid)}
                    label={getTranslationFromLangObj(nameTrans)}
                    tooltip={getTranslationFromLangObj(descriptionTrans)}
                    appendix={
                      <>
                        <img src={getOptionImagePath(code)} alt={getTranslationFromLangObj(nameTrans)} />
                      </>
                    }
                    subLabel={<>{getTranslationFromLangObj(shortDescriptionTrans)}</>}
                    onChange={() => {
                      handleChange(uuid);
                    }}
                    disabled={isMandatory}
                  />
                ))
              : ""}
          </CheckboxGroup>
        </ProductOptionContentBody>
      </ProductOptionContent>
    );
  }
);
