// eslint-disable-next-line import/no-cycle
import { RootState } from "../store";
import { wizardInitialState } from "../wizard/initialState";

export const localStorageMiddleware = ({ getState }: any) => {
  return (next: any) => (action: any) => {
    const result = next(action);
    const state: RootState = getState();
    window.localStorage.setItem(
      "wizardState",
      JSON.stringify({
        version: state.wizard.version,
        progress: state.wizard.progress,
        stepsData: state.wizard.stepsData,
        calculationData: state.wizard.calculationData,
        quotationData: state.wizard.quotationData
      })
    );
    return result;
  };
};

// eslint-disable-next-line consistent-return
export const reHydrateStore = () => {
  const state = window.localStorage.getItem("wizardState") as any;
  if (state !== null) {
    const parsedState: any = JSON.parse(state);
    const stateVersion = parsedState.version;

    if (!stateVersion || stateVersion !== wizardInitialState.version) {
      console.warn("[WizardState] Version mismatch, clearing local storage");
      window.localStorage.removeItem("wizardState");
      return {
        ...wizardInitialState
      };
    }

    return {
      ...wizardInitialState,
      ...JSON.parse(state)
    };
  }
};
