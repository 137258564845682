import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import appStateSlice from "./app/appSlice";
// eslint-disable-next-line import/no-cycle
import { localStorageMiddleware, reHydrateStore } from "./middlewares/storage";
import wizardStateSlice from "./wizard/wizardSlice";

export const store = configureStore({
  reducer: {
    app: appStateSlice,
    wizard: wizardStateSlice
  },
  preloadedState: {
    wizard: reHydrateStore()
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(localStorageMiddleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;
