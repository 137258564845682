import { useState } from "react";

import axios from "axios";

import { ApiService } from "../services/ApiService";
import { LocalStorageService } from "../services/LocalStorageService";

export const useSafeForLaterControls = () => {
  const [loader, setLoader] = useState(false);

  const getSaveForLaterRedirectUri = async (quotationId: string, orderToken: string, selectedVatNr: string | null) => {
    setLoader(true);
    try {
      await ApiService.getSaveForLaterRedirectUri(quotationId, orderToken, selectedVatNr).then((data) => {
        LocalStorageService.reset();
        window.location.href = data.data.url;
      });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        LocalStorageService.reset();
        window.location.reload();
      }
      throw e;
    }
  };

  return {
    getSaveForLaterRedirectUri,
    loader
  };
};
