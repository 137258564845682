import React from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { ReactComponent as PdfIconSvg } from "../../../../assets/images/icons/pdf-icon.svg";
import { useDesignStageControls } from "../../../../hooks/useDesignStageControls";
import { ApiService } from "../../../../services/ApiService";
import { selectSpecificStepDataValue } from "../../../../store/wizard/wizardSlice";
import { IQuotation, IQuotationInvoice } from "../../../../types/api-service";
import { WizardStepType } from "../../../../types/wizard";
import { Button } from "../../../Button/Button";
import { DownloadDesignTemplate } from "../../../common/DownloadDesignTemplate";
import { Icon } from "../../../common/Icon";
import { BlockTitle } from "../shared/BlockTitle";
import { OrderSummaryBlock } from "../shared/OrderSummaryBlock";

interface Props {
  quotationData: IQuotation;
}

export const DesignOrderSummary: React.FC<Props> = ({ quotationData }) => {
  const savedVatNr = useSelector(selectSpecificStepDataValue(WizardStepType.Order, "options", "vatNr"));
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { t } = useTranslation(["global"]);
  const { getFinishOrderButtonText, getPaymentSuccessAlert } = useDesignStageControls();

  return (
    <div className={classNames("order-step__summary", "border-top-to-md", {})}>
      <BlockTitle title={t("wizard.summary.title")} />
      {getPaymentSuccessAlert(quotationData.status)}
      <OrderSummaryBlock
        total={quotationData.totalSum}
        shipping={quotationData.deliveryPrice}
        vat={quotationData.vat}
        vatPerc={quotationData.vatRate}
        totalToPay={quotationData.totalToPay}
      />
      <div className="order-step__summary__sub-actions">
        {quotationData.invoice ? (
          <Button
            onClick={() => {
              ApiService.downloadOrderInvoice((quotationData.invoice as IQuotationInvoice).uuid).then(() => null);
            }}
            variant="link"
          >
            <PdfIconSvg /> <span className="text-underline font-padding">{t("wizard.download_invoice")}</span>
          </Button>
        ) : (
          ""
        )}

        <DownloadDesignTemplate quotationData={quotationData} />
      </div>

      <div className="order-step__summary__actions">
        <Button
          className="finish-action btn-with-arrow-icon"
          onClick={() => {
            ApiService.getFinishOrderRedirectUrl(
              quotationData.uuid,
              window.localStorage.getItem("orderToken") as string,
              savedVatNr
            ).then((data) => {
              window.location.href = data.data.url;
            });
            // window.location.href = ApiService.getFinishOrderRedirectUrl(
            //   quotationData.uuid,
            //   window.localStorage.getItem("orderToken") as string
            // );
          }}
          size="lg"
          variant="orange"
        >
          <span className="font-padding">{getFinishOrderButtonText(quotationData.status)}</span>
          {!isMobile && <Icon name="arrow-right" />}
        </Button>
      </div>
    </div>
  );
};
