import CookiesService from "../services/CookiesService";
import { ILanguageObject } from "../types/api-service";
import { Language } from "../types/language";

export const getLanguageList = () => {
  return [
    "en"
    // , "lv"
  ] as Language[];
};

export const getLanguageTranslationKey = (lang: Language) => {
  switch (lang) {
    case "en":
      return "languages.en";
    case "lv":
      return "languages.lv";
    // case "ru":
    //   return "languages.ru";
    default:
      return "languages.en";
  }
};

export const getTranslationFromLangObj = (langObj: ILanguageObject) => {
  const currentLang = (CookiesService.get("lng") as Language) || "en";
  if (langObj[currentLang]) {
    return langObj[currentLang];
  }

  return langObj.en;
};
