import React from "react";

import { useTranslation } from "react-i18next";

import { Alert } from "../components/common/Alert";
import { Icon } from "../components/common/Icon";
import { useIsUserRegistered } from "./useIsUserRegistered";

export const useDesignStageControls = () => {
  const { t } = useTranslation(["global"]);
  const isUserRegistered = useIsUserRegistered();

  const getFinishOrderButtonText = (quotationStatus: string) => {
    return quotationStatus === "quotation.status.waiting_payment"
      ? !isUserRegistered
        ? t("wizard.steps.design.create_account")
        : t("wizard.steps.design.finish_order")
      : t("wizard.steps.design.finish_order_and_login");
  };

  const getPaymentSuccessAlert = (quotationStatus: string) => {
    return quotationStatus !== "quotation.status.waiting_payment" ? (
      <Alert variant="success">
        {t("wizard.order_status")}:
        <span className="ml-auto">
          <Icon name="check-2" /> <strong>{t("wizard.order_status_paid")} / </strong> {t("wizard.stripe_payment")}
        </span>
      </Alert>
    ) : (
      ""
    );
  };

  return {
    getFinishOrderButtonText,
    getPaymentSuccessAlert
  };
};
