import { useEffect, useState } from "react";

export const useIsUserRegistered = () => {
  const [isUserRegistered, setIsUserRegistered] = useState(false);

  useEffect(() => {
    setIsUserRegistered(localStorage.getItem("token") !== null);
  });

  return isUserRegistered;
};
