import React from "react";

import { useMediaQuery } from "react-responsive";

import { ProductOptions } from "../components/Wizard/StepComponents/Product/ProductOptions";
import { ProductSummary } from "../components/Wizard/StepComponents/Product/ProductSummary";
import { ProductTypeSelector } from "../components/Wizard/StepComponents/Product/ProductTypeSelector";
import { WizardFooter } from "../components/Wizard/StepComponents/shared/WizardFooter";
import { Wizard } from "../components/Wizard/Wizard";
import { WizardContent } from "../components/Wizard/WizardContent/WizardContent";
import { useWizardStepCheck } from "../hooks/useWizardStepCheck";

export const WizardProductPage = () => {
  useWizardStepCheck();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <Wizard>
      <div className="product-step">
        <WizardContent>
          <div className="row">
            <div className="col-lg-3 col-12">
              <ProductTypeSelector />
            </div>
            <div className="col-lg-6 col-12">
              <ProductOptions />
            </div>
            <div className="col-lg-3 col-12">
              <ProductSummary />
            </div>
          </div>
          {!isMobile ? (
            <div className="row">
              <div className="col-12">
                <WizardFooter />
              </div>
            </div>
          ) : (
            ""
          )}
        </WizardContent>
      </div>
    </Wizard>
  );
};
