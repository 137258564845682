import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  resetProductStepProgress,
  selectSpecificStepDataValue,
  selectSpecificWizardApiDataValue,
  setSpecificStepDataValue
} from "../../../../store/wizard/wizardSlice";
import { WizardStepType } from "../../../../types/wizard";
import { getTranslationFromLangObj } from "../../../../utilities/language";
import { RadioButtons } from "../../../FormGroup/RadioButtons/RadioButtons";
import { BlockTitle } from "../shared/BlockTitle";
import { ImageCarousel } from "../shared/ImageCarousel";

export const ProductTypeSelector = () => {
  const { t } = useTranslation(["global"]);

  const selectedType = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "type"));
  const products = useSelector(selectSpecificWizardApiDataValue("products"));
  const dispatch = useDispatch();

  const types = useMemo(() => {
    if (!products) return [];

    return products.map((type) => ({
      value: type.uuid,
      text: getTranslationFromLangObj(type.nameTrans)
    }));
  }, [products]);

  return (
    <div className="product-step__select-type right-border">
      <BlockTitle title={t("wizard.steps.product.select_product_type")} />
      <RadioButtons
        name="product_type"
        items={types}
        value={selectedType}
        onChange={(e) => {
          dispatch(
            setSpecificStepDataValue({
              type: WizardStepType.Product,
              option: "type",
              value: e.target.value
            })
          );

          const code = products?.find((type) => type.uuid === e.target.value)?.code;

          if (code) {
            dispatch(
              setSpecificStepDataValue({
                type: WizardStepType.Product,
                option: "typeCode",
                value: code
              })
            );
          }

          dispatch(resetProductStepProgress({ force: true }));
        }}
      />
      <ImageCarousel />
    </div>
  );
};
