import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { selectPackagingTypeData } from "../store/wizard/wizardSlice";
import { IQuotation } from "../types/api-service";
import { WizardStepType } from "../types/wizard";
import { WizardStepsData } from "../types/wizard-state";
import { WizardDataHelper } from "../utilities/wizardDataHelper";
import { useHasCustomDesign } from "./useHasCustomDesign";

const templates = {
  doypack: {
    label: ["120x200.pdf", "160x230.pdf", "190x260.pdf", "200x305.pdf"],
    label_2: ["120x200.pdf", "160x230.pdf", "190x260.pdf", "200x305.pdf"],
    printed: ["120x200.pdf", "160x230.pdf", "190x260.pdf", "200x260.pdf", "235x310.pdf"],
    screen: ["120x200.pdf", "160x230.pdf", "190x260.pdf", "200x260.pdf", "235x310.pdf"]
  },
  gusset: {
    label: ["100x230.pdf", "120x290.pdf", "140x357.pdf"],
    label_2: ["100x230.pdf", "120x290.pdf", "140x357.pdf"],
    printed: ["100x230.pdf", "120x290.pdf", "140x357.pdf"],
    screen: []
  },
  flat_bottom: {
    label: ["130x200.pdf", "135x265.pdf", "150x325.pdf", "200x305.pdf"],
    label_2: ["130x200.pdf", "135x265.pdf", "150x325.pdf", "200x305.pdf"],
    printed: [
      "100g_120x200x80.pdf",
      "250g_160x230x80.pdf",
      "500g_190x260x110.pdf",
      "750g_200x260x110.pdf",
      "1000g_235x310x80.pdf"
    ],
    screen: [
      "100g_120x200x80.pdf",
      "250g_160x230x80.pdf",
      "500g_190x260x110.pdf",
      "750g_200x260x110.pdf",
      "1000g_235x310x80.pdf"
    ]
  }
};

const useDesignTemplate = (data: IQuotation | WizardStepsData[WizardStepType.Product]) => {
  const [values, setValues] = useState<{
    productType: string;
    packagingType: string;
    sizeData: [number, number, number, number, number];
  }>({
    productType: "",
    packagingType: "",
    sizeData: [0, 0, 0, 0, 0]
  });

  const [showButton, setShowButton] = useState(false);

  const selectedPackagingType = useSelector(selectPackagingTypeData);

  const [templateFilePath, setTemplateFilePath] = useState<string | null>(null);

  const hasCustomDesign = useHasCustomDesign();

  useEffect(() => {
    if (hasCustomDesign) {
      setValues({
        productType: WizardDataHelper.getProductType(WizardDataHelper.getProductTypeCodeFromData(data)),
        packagingType: WizardDataHelper.getPackagingTypeCodeValue(WizardDataHelper.getPackagingTypeCodeFromData(data)),
        sizeData: WizardDataHelper.getSizeDataFromData(data)
      });
    }
  }, [data, selectedPackagingType]);

  useEffect(() => {
    const { productType, packagingType, sizeData } = values;

    const typesWithTemplates = ["label", "label_2", "printed"];

    if (!hasCustomDesign || !typesWithTemplates.includes(packagingType)) {
      setShowButton(false);

      return;
    }

    if (productType && packagingType && sizeData) {
      const [width, height] = sizeData;
      let template;

      if (packagingType !== "stock" && packagingType !== "screen") {
        template = templates[productType as "doypack" | "flat_bottom" | "gusset"][
          packagingType as "label" | "label_2" | "printed"
        ].find((target) => {
          const [templateWidth, templateHeight] = target.split(".")[0].split("x").map(Number);

          return templateWidth === width && templateHeight === height;
        });
      }
      // } else if (packagingType === "printed") {
      //   template = templates[productType as "doypack"].printed.find((target) => {
      //     const templateGrams = Number(target.split("g_")[0]);
      //     const [templateWidth, templateHeight, templateDepth] = target
      //       .split(".")[0]
      //       .split("g_")[1]
      //       .split("x")
      //       .map(Number);

      //     return (
      //       templateGrams === grams && templateWidth === width && templateHeight === height && templateDepth === depth
      //     );
      //   });
      // }

      if (template) {
        setTemplateFilePath(`/assets/products/products/${productType}/templates/${packagingType}/${template}`);
        setShowButton(true);
      }
    }
  }, [values, hasCustomDesign]);

  return {
    templateFilePath,
    showButton
  };
};

export default useDesignTemplate;
