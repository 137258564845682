import React, { useMemo } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { selectPackagingTypeData, selectSpecificStepDataValue } from "../../../../store/wizard/wizardSlice";
import { ProductOptionTab } from "../../../../types/product-step";
import { WizardStepType } from "../../../../types/wizard";
import { Button } from "../../../Button/Button";
import { Icon } from "../../../common/Icon";
import { WarnIcon } from "../../../common/icons/WarnIcon";
import { WarnTooltip } from "../../../common/WarnTooltip";

interface Props {
  activeTab: ProductOptionTab;
  progress: { [key in ProductOptionTab]: boolean };
  onChange: (tab: ProductOptionTab) => void;
  disabled?: boolean;
  getElement: (tab: ProductOptionTab) => React.ReactNode;
}

export const ProductOptionTabs: React.FC<Props> = ({ activeTab, getElement, progress, onChange, disabled }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { t } = useTranslation(["global"]);
  const selectedOptions = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options"));
  const selectedPackagingType = useSelector(selectPackagingTypeData());

  const tabs = useMemo(() => {
    return [
      {
        text: t("wizard.steps.product.type_tab.title"),
        type: ProductOptionTab.Type,
        hasValue:
          selectedPackagingType && selectedOptions?.type !== undefined
            ? selectedPackagingType.isMaterialVariations
              ? selectedOptions?.subType !== undefined
              : true
            : false
      },
      {
        text: t("wizard.steps.product.material_tab.title"),
        type: ProductOptionTab.Material,
        hasValue: !!selectedOptions?.material
      },
      {
        text: t("wizard.steps.product.size_tab.title"),
        type: ProductOptionTab.Size,
        hasValue: !!selectedOptions?.size
      },
      {
        text: t("wizard.steps.product.options_tab.title"),
        type: ProductOptionTab.Options,
        hasValue: selectedOptions?.options?.length > 0
      }
    ];
  }, [t, selectedOptions, selectedPackagingType]);

  return (
    <div className="product-step__options__tabs">
      {tabs.map(({ type, text, hasValue }, idx) => {
        const isDisabled = disabled || (!progress[type] && type !== activeTab);
        const isCurrentTab = type === activeTab;
        return (
          <div className="option-tab" key={idx}>
            <Button
              disabled={isDisabled}
              variant={classNames({
                "border-orange-with-arrow": !disabled && isCurrentTab,
                "border-green": !disabled && progress[type] && hasValue,
                "border-red": !disabled && progress[type] && !hasValue
              })}
              onClick={() => {
                onChange(type);
              }}
              size="lg"
            >
              {progress[type] && !isCurrentTab ? (
                hasValue ? (
                  <Icon name="check-2" />
                ) : (
                  <>
                    <WarnIcon />
                    <WarnTooltip warning={t("wizard.steps.product.options_warning")} />
                  </>
                )
              ) : (
                ""
              )}
              <span className="font-padding">{text}</span>
            </Button>
            {isMobile && !isDisabled ? (
              <>
                <div className="option-tab__content">{getElement(type)}</div>
              </>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
};
