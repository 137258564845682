export interface IConstructorData {
  products: IProduct[];
  packagingTypes: IPackagingType[];
  materialVariations: IMaterialVariation[];
  materials: IMaterial[];
  pouchOptions: IPouchOption[];
  packageSizes: IPackageSize[];
}

export interface ITranslatedNameField {
  nameTrans: ILanguageObject;
}

export interface ITranslatedTextFields extends ITranslatedNameField {
  descriptionTrans: ILanguageObject;
  shortDescriptionTrans: ILanguageObject;
}

export interface IProduct extends ITranslatedNameField {
  uuid: string;
  code: string;
  // pouchOptions: IPouchOption[];
  // packageSizes: IPackageSize[];
  packagingTypes: IPackagingType[];
}

export interface IPackagingType extends ITranslatedTextFields {
  uuid: string;
  code: string;
  isMaterialVariations: boolean;
  hasCustomDesign: boolean;
  pouchOptions: IPouchOption[];
  packageSizes: IPackageSize[];
  materials: IMaterial[];
}

export interface IMaterialVariation extends ITranslatedTextFields {
  uuid: string;
  code: string;
  materials: IMaterial[];
}

export interface IMaterial extends ITranslatedTextFields {
  uuid: string;
  code: string;
}

export interface IPouchOption extends ITranslatedTextFields {
  uuid: string;
  code: string;
  isMandatory: boolean;
}

export interface IPackageSize {
  uuid: string;
  grams: number;
  height: number;
  width: number;
  depth: number;
  ml: number;
}

export interface ILanguageObject {
  lv: string;
  en: string;
}

export interface IPriceCalculateResponse {
  quantity: number;
  unitPrice: string;
  totalSum: string;
}

export interface ICountry {
  name: string;
  code: string;
}

export interface IQuotationEntity extends ITranslatedNameField {
  uuid: string;
  code: string;
}

export interface IQuotation {
  uuid: string;
  status: string;
  fullLeadDays: number;
  deliveryDate: string;
  product: IQuotationEntity;
  packagingType: IQuotationEntity;
  material: IQuotationEntity;
  materialVariation: IQuotationEntity | null;
  pouchOptions: IQuotationEntity[];
  packageSize: IPackageSize;

  invoice?: IQuotationInvoice;

  uploads?: IQuotationUpload[];

  quantity: number;
  designAmount: number;
  unitPrice: string;
  totalSum: string;

  orderNumber?: string;

  deliveryPrice?: string;
  vat?: string;
  vatRate?: number;
  totalToPay?: string;
  orderToken: string;
}

export function instanceOfQuotation(object: any): object is IQuotation {
  return "uuid" in object && "status" in object && "fullLeadDays" in object && "deliveryDate" in object;
}

export interface IQuotationInvoice {
  uuid: string;
  invoiceNumber: string;
}

export interface IQuotationUpload {
  uuid: string;
  fileName: string;
}

export interface SaveOrderParams {
  quotationId?: string;
  designAmount?: number;
  quantity?: number;
  productId: string;
  packagingTypeId?: string;
  materialVariationId?: string;
  materialId?: string;
  packageSizeId?: string;
  pouchOptions?: string[];
}

export interface IOrderSummary {
  quantity: number;
  unitPrice: string;
  totalSum: string;
  totalSumToPay: string;
  deliveryPrice: string;
  deliveryDate: string;
  vat: string;
  vatRate: number;
}

export interface IPaymentOrderTokenResponse {
  stripe: {
    url: string;
  };
  token: string;
}

export interface IRedirectUri {
  data: {
    url: string;
  };
}

export interface ICustomerRegisterResponse {
  uuid: string;
  orderToken: string;
  customerVat?: string;
}

export interface IVatValidationResponse {
  isValid: boolean;
}

export interface IHandleSuccessPaymentResponse {
  quotation: {
    uuid: string;
    status: string;
  };
  invoice: {
    uuid: string;
    invoiceNumber: string;
  };
  totalSum: string;
  deliveryPrice: string;
  vat: string;
  vatRate: number;
  totalToPay: string;
}
