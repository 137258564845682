import React from "react";

import { CSSTransition } from "react-transition-group";

import { ReactComponent as LoaderSvg } from "../../assets/images/icons/loader.svg";

interface Props {
  withoutBackdrop?: boolean;
  variant?: "standard" | "small" | "xs";
  leftPosition?: boolean;
  fullBlind?: boolean;
  smallerRadius?: boolean;
  show: boolean;
}

const Loader: React.FC<Props> = (props) => {
  const classNames = ["loader"];

  if (props.withoutBackdrop) {
    classNames.push("loader--without-backdrop");
  }

  if (props.leftPosition) {
    classNames.push("loader--left-position");
  }

  if (props.variant) {
    classNames.push(`loader--${props.variant}`);
  }

  if (props.fullBlind) {
    classNames.push(`loader--blind`);
  }

  if (props.smallerRadius) {
    classNames.push(`loader--smaller-radius`);
  }

  return (
    <CSSTransition in={props.show} unmountOnExit timeout={300} classNames="fade-faster">
      <div className={classNames.join(" ")}>
        <LoaderSvg />
      </div>
    </CSSTransition>
  );
};

Loader.defaultProps = {
  variant: "standard",
  fullBlind: false
};

export default Loader;
