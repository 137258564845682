import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import Loader from "../components/common/Loader";
import { useAppDispatch } from "../hooks/redux";
import { ApiService } from "../services/ApiService";
import { resetState, setSpecificStepDataValue } from "../store/wizard/wizardSlice";
import { WizardStepType } from "../types/wizard";

export const SampleOrder = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetState());
    ApiService.getConstructorData().then((data) => {
      dispatch(
        setSpecificStepDataValue({
          type: WizardStepType.Product,
          option: "typeCode",
          value: "pouch_type.sample"
        })
      );
      const productId = data.products?.find((type) => type.code === "pouch_type.sample")?.uuid;
      if (productId) {
        dispatch(
          setSpecificStepDataValue({
            type: WizardStepType.Product,
            option: "type",
            value: productId
          })
        );
      }
      navigate("/");
    });
  }, []);

  return (
    <div>
      <Loader show fullBlind />
    </div>
  );
};
