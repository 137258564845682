import React from "react";

import { ReactComponent as InfoIconSvg } from "../../assets/images/icons/info-icon.svg";

interface Props {
  tooltip: string;
}

export const FormGroupTooltip: React.FC<Props> = ({ tooltip }) => {
  return (
    <div className="form-group__tooltip">
      <InfoIconSvg data-tip={tooltip} data-for="with-info-icon" />
    </div>
  );
};
