import React, { useCallback, useEffect, useState } from "react";

import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import { ReactComponent as InfoIconSvg } from "../../../../assets/images/icons/info-icon.svg";
import { ApiService } from "../../../../services/ApiService";
import { selectSpecificStepDataValue, setSpecificStepDataValue } from "../../../../store/wizard/wizardSlice";
import { WizardStepType } from "../../../../types/wizard";
import { Icon } from "../../../common/Icon";
import Loader from "../../../common/Loader";
import { WarnTooltip } from "../../../common/WarnTooltip";
import { FormGroupAppendix } from "../../../FormGroup/FormGroupAppendix";
import { InputField } from "../../../FormGroup/InputField/InputField";

interface Props {
  quotationId: string;
  quotationToken: string;
  selectedCountry: string;
}

export const VatNumber: React.FC<Props> = ({ quotationId, quotationToken, selectedCountry }) => {
  const { t } = useTranslation(["global"]);
  const dispatch = useDispatch();

  const [vatNr, setVatNr] = useState("");
  const [loader, setLoader] = useState(false);
  const [isValidVat, setIsValidVat] = useState<boolean | null>(null);
  const savedVatNr = useSelector(selectSpecificStepDataValue(WizardStepType.Order, "options", "vatNr"));

  const onChangeHandler = useCallback(
    async (value: string, country: string) => {
      setLoader(true);
      const { isValid } = await ApiService.validateVatNumber(quotationId, quotationToken, value, country);
      dispatch(
        setSpecificStepDataValue({
          type: WizardStepType.Order,
          option: "options",
          subOption: "vatNr",
          value: isValid ? value : ""
        })
      );
      setIsValidVat(isValid);
      setLoader(false);
    },
    [quotationId]
  );

  const onChangeDebounced = useCallback(debounce(onChangeHandler, 700), []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isValidVat]);

  useEffect(() => {
    if (selectedCountry && vatNr) {
      onChangeDebounced(vatNr, selectedCountry);
    }
  }, [vatNr]);

  useEffect(() => {
    if (isValidVat === true) {
      setIsValidVat(null);
      setVatNr("");
      dispatch(
        setSpecificStepDataValue({
          type: WizardStepType.Order,
          option: "options",
          subOption: "vatNr",
          value: ""
        })
      );
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (savedVatNr) {
      setVatNr(savedVatNr);
    }
  }, []);

  return (
    <div className="position-relative">
      <Loader smallerRadius show={loader} variant="small" />
      <InputField
        appendix={
          isValidVat !== null ? (
            <FormGroupAppendix isAddon>
              {isValidVat ? <Icon name="check-2" /> : <WarnTooltip warning={t("wizard.steps.order.vat_invalid")} />}
            </FormGroupAppendix>
          ) : (
            ""
          )
        }
        label={
          <span className="d-flex align-items-center">
            {t("wizard.steps.order.vat_nr")}:
            <InfoIconSvg className="ml-1" data-tip={t("wizard.steps.order.vat_nr_tooltip")} />
          </span>
        }
        type="text"
        value={vatNr}
        onChange={(e) => {
          setVatNr(e.target.value);
        }}
      />
    </div>
  );
};
