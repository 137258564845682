export class LocalStorageService {
  static reset() {
    localStorage.removeItem("wizardState");
    localStorage.removeItem("token");
    localStorage.removeItem("orderToken");
  }

  static removeTokens() {
    localStorage.removeItem("token");
    localStorage.removeItem("orderToken");
  }

  static removeWizardState() {
    localStorage.removeItem("wizardState");
  }

  static saveOrderToken(token: string) {
    localStorage.setItem("orderToken", token);
  }

  static getOrderToken() {
    return localStorage.getItem("orderToken");
  }

  static saveCustomerToken(token: string) {
    window.localStorage.setItem("token", token);
  }

  static getToken() {
    return localStorage.getItem("token");
  }
}
