import React from "react";

interface Props {
  title: string;
}

export const BlockTitle: React.FC<Props> = ({ title }) => {
  return (
    <div className="wizard__content__block-title">
      <h3>{title}</h3>
    </div>
  );
};
