import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Button } from "../components/Button/Button";
import Loader from "../components/common/Loader";
import { usePaymentControls } from "../hooks/usePaymentControls";
import { ApiService } from "../services/ApiService";
import { selectSpecificStepDataValue, selectWizardQuotationData } from "../store/wizard/wizardSlice";
import { WizardStepType } from "../types/wizard";

export const PaymentFailed = () => {
  const { getAndSaveOrderToken } = usePaymentControls();
  const { data: quotationData } = useSelector(selectWizardQuotationData);
  const [loader, setLoader] = useState(false);
  const selectedVatNr = useSelector(selectSpecificStepDataValue(WizardStepType.Order, "options", "vatNr"));
  const selectedCountry = useSelector(selectSpecificStepDataValue(WizardStepType.Order, "options", "countryCode"));

  useEffect(() => {
    if (quotationData) {
      ApiService.handleFailedPayment(quotationData.uuid).then(() => null);
    }
  }, [quotationData]);

  return (
    <div className="wizard">
      <div className="statement">
        <Loader show={loader} />
        <p>Payment is failed</p>
        {quotationData && selectedCountry ? (
          <Button
            variant="orange"
            onClick={() => {
              setLoader(true);
              getAndSaveOrderToken(quotationData.uuid, selectedCountry, selectedVatNr);
            }}
          >
            <span className="font-padding">Repeat payment</span>
          </Button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
