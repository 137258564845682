import React, { useCallback, useRef, useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import CookiesService from "../../services/CookiesService";
import { selectSelectedLanguage, setSelectedLanguage } from "../../store/app/appSlice";
import { Language } from "../../types/language";
import { getLanguageList, getLanguageTranslationKey } from "../../utilities/language";

export const LanguageSelector = () => {
  const [dropdownShow, setDropdownShow] = useState(false);
  const dropdownRef = useRef<any>(null);

  const selectedLanguage = useSelector(selectSelectedLanguage);
  const { t, i18n } = useTranslation(["global"]);
  const dispatch = useDispatch();

  const setLanguage = useCallback(async (key: Language) => {
    try {
      CookiesService.set("lng", key);

      await i18n.changeLanguage(key);

      dispatch(setSelectedLanguage(key));
    } catch (e) {
      console.error(e);
    } finally {
      setDropdownShow(false);
    }
  }, []);

  useOutsideAlerter(
    dropdownRef,
    () => {
      if (dropdownShow) {
        setDropdownShow(false);
      }
    },
    (e: any) => {
      return !e.target.closest(".language-selector__selected");
    }
  );

  return (
    <div className="language-selector">
      <button
        className="language-selector__selected"
        type="button"
        onClick={() => {
          setDropdownShow(!dropdownShow);
        }}
      >
        <span>{t(getLanguageTranslationKey(selectedLanguage as Language))}</span>
        <span className="language-selector__selected__arrow" />
      </button>
      <div
        ref={dropdownRef}
        className={classNames("language-selector__dropdown", {
          "language-selector__dropdown--show": dropdownShow
        })}
      >
        <ul>
          {getLanguageList().map((item, idx) => (
            <li key={idx}>
              <button
                type="button"
                onClick={() => {
                  setLanguage(item).then(() => null);
                }}
              >
                {t(getLanguageTranslationKey(item))}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
