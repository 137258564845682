import React, { useCallback, useEffect, useMemo, useState } from "react";

import axios from "axios";
import classNames from "classnames";
import debounce from "lodash.debounce";
import DateObject from "react-date-object";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../hooks/redux";
import { useWizardNavigation } from "../../../../hooks/useWizardNavigation";
import { ApiService } from "../../../../services/ApiService";
import { LocalStorageService } from "../../../../services/LocalStorageService";
import {
  fetchCalculationData,
  isSampleSetSelected,
  isProductSummaryDisabled,
  resetState,
  saveOrder,
  selectSpecificStepDataValue,
  selectSummaryData,
  selectWizardCalculationData,
  selectWizardQuotationData,
  setSpecificStepDataValue
} from "../../../../store/wizard/wizardSlice";
import { WizardStepType } from "../../../../types/wizard";
import { numberWithSpaces } from "../../../../utilities/formatter";
import { Button } from "../../../Button/Button";
import { Icon } from "../../../common/Icon";
import Loader from "../../../common/Loader";
import { BlockTitle } from "../shared/BlockTitle";
import { ProductSummaryInputs, QuantityRangeSettings } from "./ProductSummaryInputs";
import { EstimatedDeliveryDate } from "./shared/EstimatedDeliveryDate";

export const ProductSummary = React.memo(() => {
  const { t } = useTranslation(["global"]);
  const dispatch = useAppDispatch();

  const [saveOrderProcessing, setSaveOrderProcessing] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [quantityRangesLoader, setQuantityRangesLoader] = useState(false);
  // const [isQuantityRangeExceed, setIsQuantityRangeExceed] = useState(false);
  const [summaryInputs, setSummaryInputs] = useState({
    quantity: 0,
    designAmount: 0
  });
  const [quantityRanges, setQuantityRanges] = useState<QuantityRangeSettings>({
    min: null,
    max: null
  });

  const disabled = useSelector(isProductSummaryDisabled);
  const sampleSetSelected = useSelector(isSampleSetSelected);
  const [wizardBeingUpdated, setWizardBeingUpdated] = useState(!sampleSetSelected);
  const summaryData = useSelector(selectSummaryData);
  const selectedProduct = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "type"));
  const selectedPackageType = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "type"));
  const selectedMaterialVariation = useSelector(
    selectSpecificStepDataValue(WizardStepType.Product, "options", "subType")
  );
  const selectedMaterial = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "material"));
  const selectedSize = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "size"));
  const selectedOptions = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "options"));
  const { data: quotationData, loader: quotationDataLoader } = useSelector(selectWizardQuotationData);
  const {
    data: calculationData,
    loader: calculationDataLoader,
    isSuccess: success
  } = useSelector(selectWizardCalculationData);

  const { goToNextStep } = useWizardNavigation();

  const isDisabled = useMemo(() => {
    return disabled || summaryData === null;
  }, [disabled, summaryData]);

  const getQuotationRanges = useCallback(
    async (
      selectedMaterialId: string,
      selectedSizeId: string,
      selectedPackageTypeId: string,
      selectedProductId: string,
      selectedMaterialVariationId: string | undefined,
      selectedPouchOptionsIds: string[] | undefined
    ) => {
      if (!selectedMaterialId || !selectedSizeId) return;
      setQuantityRangesLoader(true);
      // setButtonDisabled(true);

      try {
        const quantityRangesData = await ApiService.getQuantityRanges(
          selectedMaterialId,
          selectedSizeId,
          selectedPackageTypeId,
          selectedProductId,
          selectedMaterialVariationId,
          selectedPouchOptionsIds
        );
        setQuantityRanges(quantityRangesData);
        setQuantityRangesLoader(false);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          LocalStorageService.reset();
          dispatch(resetState());
          // window.location.reload();
        }
        throw e;
      }
    },
    []
  );

  const getCalculationData = useCallback(
    async (
      selectedMaterialId: string,
      selectedSizeId: string,
      selectedOptionsIds: string[],
      quantity: number,
      designAmount: number,
      selectedProductId: string | undefined,
      selectedPackageTypeId: string | undefined,
      selectedMaterialVariationId: string | undefined
    ) => {
      try {
        // if (!selectedMaterialId || !selectedSizeId) return;
        if (!selectedProductId) return;

        dispatch(
          fetchCalculationData({
            materialId: selectedMaterialId,
            packageSizeId: selectedSizeId,
            quantity,
            designAmount,
            productId: selectedProductId,
            packageTypeId: selectedPackageTypeId,
            materialVariationId: selectedMaterialVariationId,
            selectedOptionsIds
          })
        );

        dispatch(
          setSpecificStepDataValue({
            type: WizardStepType.Product,
            option: "options",
            subOption: "quantity",
            value: quantity
          })
        );

        setTimeout(() => {
          setWizardBeingUpdated(false);
        }, 300);
      } catch (e) {
        console.error(e);
      }
    },
    [getQuotationRanges]
  );

  const handleSummary = useCallback(debounce(getCalculationData, 1000), []);

  const handleSummarySubmit = useCallback(() => {
    if (summaryData === null) return;
    setSaveOrderProcessing(true);

    dispatch(
      saveOrder({
        ...(quotationData ? { quotationId: quotationData.uuid } : {}),
        designAmount: summaryData.designAmount,
        quantity: summaryData.quantity,
        productId: summaryData.product.id,
        packagingTypeId: summaryData?.packagingType?.id,
        ...(summaryData.materialVariation
          ? {
              materialVariationId: summaryData.materialVariation.id
            }
          : {}),
        materialId: summaryData.material?.id,
        packageSizeId: summaryData.size?.id,
        pouchOptions: summaryData.options?.map((e) => e.id)
      })
    );
  }, [summaryData, quotationData]);

  useEffect(() => {
    if ((summaryInputs.quantity && summaryInputs.designAmount) || sampleSetSelected) {
      setWizardBeingUpdated(true);
      if (sampleSetSelected) {
        summaryInputs.quantity = 1;
      }
      handleSummary(
        selectedMaterial,
        selectedSize,
        selectedOptions,
        summaryInputs.quantity,
        summaryInputs.designAmount,
        summaryData?.product.id,
        summaryData?.packagingType?.id,
        summaryData?.materialVariation?.id
      );
    }
  }, [selectedMaterial, selectedSize, selectedOptions, summaryInputs, summaryData, sampleSetSelected]);

  useEffect(() => {
    if (!success) {
      dispatch(resetState());
      window.location.reload();
    }

    if (saveOrderProcessing && !quotationDataLoader) {
      goToNextStep();
    }
  }, [saveOrderProcessing, quotationDataLoader, success]);

  useEffect(() => {
    if (selectedMaterial && selectedSize) {
      getQuotationRanges(
        selectedMaterial,
        selectedSize,
        selectedPackageType,
        selectedProduct,
        selectedMaterialVariation,
        selectedOptions
      ).then(() => null);
    }
  }, [selectedMaterial, selectedSize, selectedPackageType, selectedMaterialVariation, selectedProduct, selectedOptions]);

  return (
    <div
      className={classNames("product-step__summary", "border-top-to-md", {
        "disabled-block": isDisabled
      })}
    >
      <Loader show={calculationDataLoader || quotationDataLoader} />
      <BlockTitle title={t("wizard.summary.title")} />

      {!sampleSetSelected ? (
        <ProductSummaryInputs
          isDisabled={isDisabled}
          quantityRanges={quantityRanges}
          quantityRangeLoader={quantityRangesLoader}
          onQuantityLimitExceeded={(isExceeded) => {
            setButtonDisabled(isExceeded);
            // setIsQuantityRangeExceed(isExceeded);
          }}
          onChange={(data) => {
            setSummaryInputs(data);
          }}
        />
      ) : (
        <div />
      )}

      {!sampleSetSelected ? (
        <div className="product-summary">
          <ul>
            <li>
              <span>{t("wizard.summary.material")}:</span>{" "}
              <span>{summaryData !== null ? summaryData.material?.name : "-"}</span>
            </li>
            <li>
              <span>{t("wizard.summary.size")}:</span>{" "}
              <span>{summaryData !== null ? summaryData.size?.name : "-"}</span>
            </li>
            <li>
              <span>{t("wizard.summary.options")}:</span>{" "}
              <span>{summaryData !== null ? summaryData.options?.map((e) => e.name).join(", ") : "-"}</span>
            </li>
            <li className="mt-3">
              <span>{t("wizard.summary.price")}:</span>
              {summaryData !== null && calculationData !== null && calculationData.unitPrice ? (
                <span>
                  {calculationData.unitPrice} €/{t("wizard.summary.per_piece")}
                </span>
              ) : (
                <span>-</span>
              )}
            </li>
          </ul>
        </div>
      ) : (
        <div />
      )}
      <div className="product-step__summary__total">
        {t("wizard.summary.total")}:{" "}
        {summaryData !== null && calculationData !== null && calculationData.totalSum ? (
          <span>{numberWithSpaces(Number(calculationData.totalSum))} €</span>
        ) : (
          <span>-</span>
        )}
      </div>

      <div className="product-step__summary__button">
        <EstimatedDeliveryDate>
          {t(`wizard.steps.product.summary.${selectedSize || sampleSetSelected ? "stock" : "default"}.description`)}
          {new DateObject().add(14, "d").format("DD.MM.YYYY")}
        </EstimatedDeliveryDate>
        <Button
          variant="orange"
          disabled={disabled || buttonDisabled || wizardBeingUpdated || quantityRangesLoader}
          onClick={() => {
            handleSummarySubmit();
          }}
        >
          <span className="font-padding">{t("wizard.summary.finish_your_order")}</span> <Icon name="arrow-right" />
        </Button>
      </div>
    </div>
  );
});
