import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import ReactTooltip from "react-tooltip";

import { IQuotation } from "../../../../types/api-service";
import { DownloadDesignTemplate } from "../../../common/DownloadDesignTemplate";
import { BlockTitle } from "../shared/BlockTitle";
import { ImageCarousel } from "../shared/ImageCarousel";
import { BackButton } from "./BackButton";

interface Props {
  hideDownloadTemplateButton?: boolean;
  hideBackButton?: boolean;
  showSizesOverwrite?: boolean;
  quotationData?: IQuotation;
}

export const OrderImagePreview: React.FC<Props> = ({
  hideDownloadTemplateButton,
  hideBackButton,
  showSizesOverwrite,
  quotationData
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { t } = useTranslation(["global"]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div>
      <BlockTitle title={t("wizard.steps.product.title")} />
      <ImageCarousel showSizesOverwrite={showSizesOverwrite} quotationData={quotationData} />

      {!hideDownloadTemplateButton ? (
        <div className="mt-3">
          <DownloadDesignTemplate />
        </div>
      ) : (
        ""
      )}

      {!hideBackButton && !isMobile ? (
        <div className="mt-3">
          <BackButton />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
