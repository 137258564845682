import axios from "axios";
import download from "downloadjs";

import {
  IConstructorData,
  ICountry,
  ICustomerRegisterResponse,
  IHandleSuccessPaymentResponse,
  IOrderSummary,
  IPaymentOrderTokenResponse,
  IPriceCalculateResponse,
  IQuotation,
  IQuotationUpload,
  IRedirectUri,
  IVatValidationResponse,
  SaveOrderParams
} from "../types/api-service";
import { getAuthHeader, getOrderTokenHeader } from "./utils";

let PreviousSummaryController: AbortController | null = null;

export class ApiService {
  static baseEndpoint = process.env.REACT_APP_API_BASE_ENDPOINT;

  static async getConstructorData(): Promise<IConstructorData> {
    try {
      // return mock.data as IConstructorData;

      const res = await axios.get(`${ApiService.baseEndpoint}/constructor/data/`);

      return res.data.data;
    } catch (e) {
      throw e;
    }
  }

  static async getCountryList(): Promise<ICountry[]> {
    try {
      const res = await axios.get(`${ApiService.baseEndpoint}/countries/`);

      return res.data.countries;
    } catch (e) {
      throw e;
    }
  }

  static async calculateSummary(
    materialId: string | undefined,
    packageSizeId: string | undefined,
    quantity: number | undefined,
    designAmount: number | undefined,
    productId: string | undefined,
    packageTypeId: string | undefined,
    materialVariationId: string | undefined,
    selectedPouchOptionsIds: string[] | undefined
  ): Promise<IPriceCalculateResponse> {
    try {
      const queryParams = new URLSearchParams();

      // queryParams.set("materialUuid", materialId);
      // queryParams.set("packageSizeUuid", packageSizeId);
      // queryParams.set("quantity", quantity.toString());
      // queryParams.set("designAmount", designAmount.toString());
      if (typeof materialId === "string") {
        queryParams.set("materialUuid", materialId);
      }
      if (typeof packageSizeId === "string") {
        queryParams.set("packageSizeUuid", packageSizeId);
      }
      if (typeof quantity === "number") {
        queryParams.set("quantity", quantity.toString());
      }
      if (typeof designAmount === "number") {
        queryParams.set("designAmount", designAmount.toString());
      }
      if (typeof productId === "string") {
        queryParams.set("productUuid", productId);
      }
      if (typeof packageTypeId === "string") {
        queryParams.set("packagingTypeUuid", packageTypeId);
      }
      if (typeof materialVariationId === "string") {
        queryParams.set("materialVariationUuid", materialVariationId);
      }
      if (selectedPouchOptionsIds) queryParams.set("pouchOptionsUuids", selectedPouchOptionsIds.toString());

      const res = await axios.get(`${ApiService.baseEndpoint}/price/calculate/?${queryParams.toString()}`);

      return res.data.data;
    } catch (e) {
      throw e;
    }
  }

  static async getOrderSummary(quotationId: string, countryCode: string, vatNr: string | null): Promise<IOrderSummary> {
    try {
      if (PreviousSummaryController !== null) {
        (PreviousSummaryController as AbortController).abort();
      }

      PreviousSummaryController = new AbortController();

      let res;
      const axiosData = {
        country: countryCode,
        vatNr,
        quotation: {
          uuid: quotationId
        }
      };
      if (!localStorage.getItem("token")) {
        res = await axios.post(`${ApiService.baseEndpoint}/price/summary/`, axiosData, {
          signal: PreviousSummaryController.signal
        });
      } else {
        res = await axios.post(`${ApiService.baseEndpoint}/price/summary/secured/`, axiosData, {
          headers: getAuthHeader(),
          signal: PreviousSummaryController.signal
        });
      }

      PreviousSummaryController = null;
      return res.data.data;
    } catch (e) {
      throw e;
    }
  }

  // static async getTemplateDesignFile(): Promise<any> {
  //   try {
  //     const res = await axios.get(`${ApiService.baseEndpoint}/design/template/download/`, {
  //       responseType: "blob"
  //     });

  //     const content = res.headers["content-type"];
  //     download(res.data, "template", content);
  //   } catch (e) {
  //     throw e;
  //   }
  // }

  static async saveOrder(data: SaveOrderParams): Promise<IQuotation> {
    try {
      const res = await axios.post(`${ApiService.baseEndpoint}/order/save/`, {
        designAmount: data.designAmount,
        quantity: data.quantity,
        product: {
          uuid: data.productId
        },
        ...(data.quotationId
          ? {
              quotation: {
                uuid: data.quotationId
              }
            }
          : {}),
        packagingType: {
          uuid: data.packagingTypeId
        },
        ...(data.materialVariationId
          ? {
              materialVariation: {
                uuid: data.materialVariationId
              }
            }
          : {}),
        material: {
          uuid: data.materialId
        },
        packageSize: {
          uuid: data.packageSizeId
        },
        pouchOptions: data.pouchOptions?.map((e) => ({
          uuid: e
        }))
      });

      return res.data.data.quotation;
    } catch (e) {
      throw e;
    }
  }

  static async getOrderData(quotationId: string): Promise<IQuotation> {
    try {
      const res = await axios.get(`${ApiService.baseEndpoint}/order/${quotationId}/data/`, {
        headers: getOrderTokenHeader()
        // headers: getAuthHeader()
      });

      return res.data.data.quotation;
    } catch (e) {
      throw e;
    }
  }

  static async deleteOrderUpload(uploadId: string): Promise<void> {
    try {
      await axios.post(
        `${ApiService.baseEndpoint}/uploads/${uploadId}/remove/`,
        {},
        {
          headers: getOrderTokenHeader()
          // headers: getAuthHeader()
        }
      );
    } catch (e) {
      throw e;
    }
  }

  static async downloadOrderInvoice(id: string): Promise<void> {
    try {
      const res = await axios.get(`${ApiService.baseEndpoint}/invoices/${id}/download/`, {
        responseType: "blob",
        headers: getOrderTokenHeader()
        // headers: getAuthHeader()
      });

      const content = res.headers["content-type"];
      download(res.data, "invoice", content);
    } catch (e) {
      throw e;
    }
  }

  static async downloadUploadById(id: string, fileName: string): Promise<void> {
    try {
      const res = await axios.get(`${ApiService.baseEndpoint}/uploads/${id}/download/`, {
        responseType: "blob",
        headers: getOrderTokenHeader()
        // headers: getAuthHeader()
      });

      const content = res.headers["content-type"];
      download(res.data, fileName, content);
    } catch (e) {
      throw e;
    }
  }

  static async uploadDesignToOrder(quotationId: string, formData: FormData): Promise<IQuotationUpload[]> {
    try {
      const res = await axios.post(`${ApiService.baseEndpoint}/order/${quotationId}/design/upload/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          ...getOrderTokenHeader()
          // ...getAuthHeader()
        }
      });

      return res.data.data.quotation.uploads;
    } catch (e) {
      throw e;
    }
  }

  static async getOrderToken(
    quotationId: string,
    countryCode: string,
    vatNr: string | null
  ): Promise<IPaymentOrderTokenResponse> {
    try {
      let res;
      const axiosData = {
        country: countryCode,
        successUrl: `${window.location.origin}/payment/success`,
        failUrl: `${window.location.origin}/order`,
        vatNr,
        quotation: {
          uuid: quotationId
        }
      };

      if (!localStorage.getItem("token")) {
        res = await axios.post(`${ApiService.baseEndpoint}/payments/session/create/`, axiosData);
      } else {
        res = await axios.post(`${ApiService.baseEndpoint}/payments/session/create/secured/`, axiosData, {
          headers: getAuthHeader()
        });
      }
      return res.data.data;
    } catch (e) {
      throw e;
    }
  }

  static async handleSuccessPayment(paymentId: string): Promise<IHandleSuccessPaymentResponse> {
    try {
      const res = await axios.post(
        `${ApiService.baseEndpoint}/payments/${paymentId}/paid/`,
        {},
        {
          headers: getOrderTokenHeader()
          // headers: getAuthHeader()
        }
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  static async handleFailedPayment(quotationId: string): Promise<IHandleSuccessPaymentResponse> {
    try {
      const res = await axios.post(
        `${ApiService.baseEndpoint}/order/${quotationId}/payments/failed/`,
        {},
        {
          headers: getOrderTokenHeader()
          // headers: getAuthHeader()
        }
      );

      return res.data;
    } catch (e) {
      throw e;
    }
  }

  static async getQuantityRanges(
    materialId: string,
    sizeId: string,
    selectedPackageTypeId: string,
    selectedProductId: string,
    selectedMaterialVariationId: string | undefined,
    selectedPouchOptionsIds: string[] | undefined
  ): Promise<{
    min: number;
    max: number;
  }> {
    try {
      const searchParams = new URLSearchParams();
      searchParams.set("materialUuid", materialId);
      searchParams.set("packageSizeUuid", sizeId);
      searchParams.set("packagingTypeUuid", selectedPackageTypeId);
      searchParams.set("productUuid", selectedProductId);
      if (selectedMaterialVariationId) searchParams.set("materialVariationUuid", selectedMaterialVariationId);
      if (selectedPouchOptionsIds) searchParams.set("pouchOptionsUuids", selectedPouchOptionsIds.toString());

      const res = await axios.get(`${ApiService.baseEndpoint}/quantities/range/?${searchParams.toString()}`);

      return {
        min: Number(res.data.data.quantities.min),
        max: Number(res.data.data.quantities.max)
      };
    } catch (e) {
      throw e;
    }
  }

  static async getSaveForLaterRedirectUri(
    quotationId: string,
    orderToken: string,
    vatNr: string | null
  ): Promise<IRedirectUri> {
    try {
      let res: IRedirectUri;
      const searchParams = new URLSearchParams();
      searchParams.set("quotationUuid", quotationId);
      searchParams.set("orderToken", orderToken);
      if (vatNr) {
        searchParams.set("vatNr", vatNr);
      }
      if (!localStorage.getItem("token")) {
        res = await axios.get(`${ApiService.baseEndpoint}/redirect/save-for-later/?${searchParams.toString()}`);
      } else {
        res = await axios.get(
          `${ApiService.baseEndpoint}/redirect/save-for-later/secured/?${searchParams.toString()}`,
          {
            headers: getAuthHeader()
          }
        );
      }
      return res;
    } catch (e) {
      throw e;
    }
    // return `${ApiService.baseEndpoint}/redirect/save-for-later/?${searchParams.toString()}`;
  }

  static async getFinishOrderRedirectUrl(
    quotationId: string,
    token: string,
    vatNr: string | null
  ): Promise<IRedirectUri> {
    try {
      let res: IRedirectUri;
      const searchParams = new URLSearchParams();
      searchParams.set("quotationUuid", quotationId);
      searchParams.set("orderToken", token);
      if (vatNr) {
        searchParams.set("vatNr", vatNr);
      }
      if (!localStorage.getItem("token")) {
        res = await axios.get(`${ApiService.baseEndpoint}/redirect/order/finish/?${searchParams.toString()}`);
      } else {
        res = await axios.get(
          `${ApiService.baseEndpoint}/redirect/order/${quotationId}/finish/secured/?${searchParams.toString()}`,
          {
            headers: getAuthHeader()
          }
        );
      }
      return res;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.request.status === 401) {
          window.localStorage.removeItem("token");
          window.location.reload();
        }
      }
      throw e;
    }
  }

  static async validateVatNumber(
    quotationId: string,
    orderToken: string,
    vatNumber: string,
    countryCode: string
  ): Promise<IVatValidationResponse> {
    try {
      const vatNr = vatNumber.replace(/\s/g, "");
      const res = await axios.get(
        `${ApiService.baseEndpoint}/order/${quotationId}/vat/${encodeURIComponent(vatNr)}/country/${encodeURIComponent(
          countryCode
        )}/`,
        {
          headers: getOrderTokenHeader(orderToken)
        }
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  static async createInvoiceForRegisteredUser(quotationId: string): Promise<ICustomerRegisterResponse> {
    try {
      const res = await axios.post(
        `${ApiService.baseEndpoint}/order/${quotationId}/invoices/create/secured/`,
        {},
        {
          headers: getAuthHeader()
        }
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  static async submitCustomerRegisterForm(
    email: string,
    phone: string,
    vatNr: string | null,
    country: string,
    name: string,
    quotationId: string,
    orderToken: string
  ): Promise<ICustomerRegisterResponse> {
    try {
      const axiosData = {
        name,
        country,
        vatNr,
        email,
        phone
      };
      const res = await axios.post(
        `${ApiService.baseEndpoint}/order/${quotationId}/customer/register-and-invoice-create/`,
        axiosData,
        {
          headers: getOrderTokenHeader(orderToken)
        }
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  }
}
