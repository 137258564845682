import React from "react";

import { useTranslation } from "react-i18next";

import useModal from "../../../../hooks/useModal";
import { Button } from "../../../Button/Button";
import { Modal } from "../../../Modal/Modal";

interface Props {}

export const WizardFooter: React.FC<Props> = () => {
  const { t } = useTranslation(["global"]);
  const appUrl = process.env.REACT_APP_CUSTOMER_PROFILE_ENDPOINT;

  const { show, toggle } = useModal();

  return (
    <>
      <div className="product-step__options__footer">
        <Button onClick={toggle} variant="link-underline">
          <span className="font-padding">{t("wizard.how_to_order")}</span>
        </Button>
        <Button
          onClick={() => {
            // @ts-ignore
            window.location.href = appUrl;
          }}
          variant="link-underline"
        >
          <span className="font-padding">{t("wizard.link_to_calculator")}</span>
        </Button>
      </div>

      <Modal show={show} className="how-to-order-modal" preventContentWrapper onHide={toggle}>
        <iframe
          width="420"
          height="315"
          src="https://www.youtube.com/embed/_PTsLBj1MkU?autoplay=1"
          title="How to order?"
        />
      </Modal>
    </>
  );
};
