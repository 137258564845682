import React, { useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import { ReactComponent as TrashSvg } from "../../../../assets/images/icons/trash-icon.svg";
import { ApiService } from "../../../../services/ApiService";
import { IQuotationUpload } from "../../../../types/api-service";
import Loader from "../../../common/Loader";

interface Props {
  uploads: IQuotationUpload[];
  onUploadDelete: (id: string) => void;
}

interface DesignOrderUploadProps {
  upload: IQuotationUpload;
  index: number;
  onDeleteClick: (id: string) => void;
}

const DesignOrderUpload: React.FC<DesignOrderUploadProps> = ({ upload, index, onDeleteClick }) => {
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation(["global"]);

  return (
    <li>
      <Loader variant="xs" show={loader} />
      <span>
        {t("wizard.steps.design.design_nr")}
        {index + 1}:
      </span>{" "}
      <button
        className="download-btn"
        type="button"
        onClick={() => {
          ApiService.downloadUploadById(upload.uuid, upload.fileName.split(".pdf")[0]).then(() => null);
        }}
      >
        {upload.fileName}
      </button>{" "}
      <button
        className="remove-btn"
        type="button"
        onClick={() => {
          setLoader(true);
          onDeleteClick(upload.uuid);
        }}
      >
        <TrashSvg />
      </button>
    </li>
  );
};

export const DesignOrderUploads: React.FC<Props> = ({ uploads, onUploadDelete }) => {
  const handleRemoveUpload = useCallback(async (id: string) => {
    try {
      await ApiService.deleteOrderUpload(id);
      onUploadDelete(id);
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <ul className="order-step__data__uploads">
      {uploads.map((e, idx) => (
        <DesignOrderUpload key={e.uuid} upload={e} index={idx} onDeleteClick={handleRemoveUpload} />
      ))}
    </ul>
  );
};
