import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useHasCustomDesign } from "../../../../hooks/useHasCustomDesign";
import { isSampleSetSelected } from "../../../../store/wizard/wizardSlice";
import { ISummaryData } from "../../../../types/wizard-state";
import { numberWithSpaces } from "../../../../utilities/formatter";
import { Checkbox } from "../../../FormGroup/Checkbox/ Checkbox";

interface Props {
  summaryData: ISummaryData | null;
  designAmountValue: number;
  piecesCountValue: number;
  unitPrice?: string;
  onSizeShow: (value: boolean) => void;
}

export const OrderProductSummary: React.FC<Props> = ({
  summaryData,
  designAmountValue,
  piecesCountValue,
  unitPrice,
  onSizeShow
}) => {
  const { t } = useTranslation(["global"]);

  const sampleSetSelected = useSelector(isSampleSetSelected);
  const [showSizes, setShowSizes] = useState(false);
  const hasCustomDesign = useHasCustomDesign();

  useEffect(() => {
    onSizeShow(showSizes);
  }, [showSizes]);

  return (
    <div className="product-summary">
      <ul>
        {!sampleSetSelected ? (
          <li>
            <span>{t("wizard.summary.material")}:</span>{" "}
            <span>{summaryData !== null ? summaryData.material?.name : "-"}</span>
          </li>
        ) : (
          <li>
            <span>{t("wizard.summary.sample")}</span>{" "}
          </li>
        )}
        {!sampleSetSelected ? (
          <li>
            <span>{t("wizard.summary.size")}:</span>{" "}
            <div className="summary-sizes">
              <span>{summaryData !== null ? summaryData.size?.name : "-"}</span>
              <Checkbox
                value={showSizes}
                size="sm"
                label={t("wizard.summary.show_sizes")}
                onChange={() => {
                  setShowSizes(!showSizes);
                }}
                disabled={false}
              />
            </div>
          </li>
        ) : (
          <li />
        )}
        {!sampleSetSelected ? (
          <li>
            <span>{t("wizard.summary.options")}:</span>{" "}
            <span>{summaryData !== null ? summaryData.options?.map((e) => e.name).join(", ") : "-"}</span>
          </li>
        ) : (
          ""
        )}
        {hasCustomDesign ? (
          <li className="mt-3">
            <span>{t("wizard.steps.product.design_amount")}:</span>
            <span>{numberWithSpaces(Number(designAmountValue))}</span>
          </li>
        ) : (
          ""
        )}
        <li>
          <span>{t("wizard.steps.product.quantity_pieces")}:</span>
          <span>{numberWithSpaces(piecesCountValue)}</span>
        </li>
        <li>
          <span>{t("wizard.summary.price_per_piece")}:</span>
          {summaryData !== null && unitPrice ? <span>{unitPrice} €</span> : ""}
        </li>
      </ul>
    </div>
  );
};
