export const useSizesControls = () => {
  const getSizesMeasure = (productCode: string, grams: number, ml: number) => {
    return productCode !== "pouch_type.doypack" ? `${grams}g` : `${ml}ml`;
  };

  const getSizesTooltip = (productCode: string, grams: number, ml: number) => {
    return productCode === "pouch_type.doypack"
      ? `${grams}g package`
      : productCode === "pouch_type.flat_bottom"
      ? `${ml}ml package`
      : undefined;
  };

  const getSizesText = (productCode: string, grams: number, ml: number, width: number, height: number, depth: number) => {
    const measure = productCode !== "packaging_type.stickers" ? `${getSizesMeasure(productCode, grams, ml)} ` : '';
    const dimensions = `${width} x ${height}${productCode !== "packaging_type.stickers" ? ` x ${depth}` : ''}mm`;
    return `${measure}   ${dimensions}`;
  };

  return {
    getSizesMeasure,
    getSizesTooltip,
    getSizesText
  };
};
