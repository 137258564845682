import { WizardStepType } from "../types/wizard";

export const getRouteBasedOnProgress = (activeStep: WizardStepType) => {
  switch (activeStep) {
    case WizardStepType.Product: {
      return "/";
    }
    case WizardStepType.Order: {
      return "/order";
    }
    case WizardStepType.Design: {
      return "/design";
    }
    default: {
      return "/";
    }
  }
};
