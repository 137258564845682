import React, { useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { Button } from "../../Button/Button";
import { Alert } from "../../common/Alert";
import { Icon } from "../../common/Icon";
import { FormGroupAppendix } from "../../FormGroup/FormGroupAppendix";
import { InputField } from "../../FormGroup/InputField/InputField";
import { FooterCol } from "./FooterCol";

export const FooterSubscribeCol = () => {
  const { t } = useTranslation(["global"]);

  const [emailValue, setEmailValue] = useState("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const submit = useCallback(async () => {
    try {
      // todo: api request

      if (!emailValue) {
        window.alert("Enter an valid email!");
        return;
      }

      setFormSubmitting(true);

      // await new Promise((resolve) => setTimeout(resolve, 1000));
      setFormSubmitted(true);
    } catch (e) {
      console.error(e);
    } finally {
      setFormSubmitting(false);
    }
  }, [emailValue]);

  return (
    <FooterCol title={t("layout.footer.subscribe_to_our_newsletter")} className="footer__col--subscribe">
      {formSubmitted ? (
        <Alert variant="success">
          <p>{t("layout.footer.email_added_to_our_maillist")}</p>
        </Alert>
      ) : (
        <>
          <InputField
            size="lg"
            type="email"
            required
            variant="gray"
            value={emailValue}
            placeholder={t("layout.footer.your_email_address")}
            appendix={
              <FormGroupAppendix>
                <Button disabled={formSubmitting} variant="orange" onClick={() => submit()} size="lg">
                  {!isMobile ? <span className="font-padding">{t("layout.footer.subscribe")}</span> : ""}{" "}
                  <Icon name="send" isWhite />
                </Button>
              </FormGroupAppendix>
            }
            onChange={(e) => {
              setEmailValue(e.target.value);
            }}
          />
          <p className="footer__col--subscribe__sub-text">{t("layout.footer.subscribe_to_receive_our_newsletter")}</p>
        </>
      )}
    </FooterCol>
  );
};
