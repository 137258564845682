import React from "react";

import ReactDOM from "react-dom";

interface Props {
  children: any;
  className: string;
  el?: string;
}

const Portal = ({ children, className = "root-portal", el = "div" }: Props) => {
  const [container] = React.useState(document.createElement(el));
  const rootContainer = document.querySelector("#root") as HTMLDivElement;

  container.classList.add(className);

  React.useEffect(() => {
    rootContainer.appendChild(container);
    return () => {
      rootContainer.removeChild(container);
    };
  }, []);

  return ReactDOM.createPortal(children, container);
};

export default Portal;
