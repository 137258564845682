import React from "react";

import classNames from "classnames";

interface Props {
  children: any;
  className?: string | string[] | { [key: string]: boolean };
}
export const FormGroup: React.FC<Props> = ({ children, className }) => {
  return <div className={classNames("form-group", className)}>{children}</div>;
};
