import React, { useEffect, useMemo, useRef } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";

import useOutsideClick from "../../hooks/useOutsideAlerter";
import { Button } from "../Button/Button";
import Portal from "../common/Portal";

interface Props {
  show: boolean;
  headerTitle?: any;
  onHide?: () => void;
  onShow?: () => void;
  preventContentWrapper?: boolean;
  [key: string]: any;
}

export const Modal: React.FC<Props & React.HtmlHTMLAttributes<HTMLDivElement>> = (props) => {
  const { show, preventContentWrapper = false, headerTitle, children, onHide, className, ...rest } = props;
  const modalRef = useRef(null);

  const { t } = useTranslation(["global"]);

  useOutsideClick(modalRef, () => {
    if (onHide) {
      onHide();
    }
  });

  useEffect(() => {
    if (show) {
      document.body.classList.add("prevent-scroll");
    } else {
      document.body.classList.remove("prevent-scroll");
    }
  }, [show]);

  const closeButton = useMemo(() => {
    return (
      <Button
        variant="link"
        onClick={() => {
          if (onHide) {
            onHide();
          }
        }}
      >
        {t("layout.modal.close")}
      </Button>
    );
  }, [onHide]);

  return (
    <Portal className="modal-portal" el="div">
      <CSSTransition in={show} unmountOnExit timeout={500} classNames="fade">
        <div className="modal">
          <div className={classNames("modal-content", className)} ref={modalRef} {...rest}>
            {headerTitle ? (
              <div className="modal-content__header">
                <span>{headerTitle}</span>
              </div>
            ) : (
              ""
            )}
            {preventContentWrapper ? children : <div className="modal-content__body">{children}</div>}
            <div className="modal-content__footer">{closeButton}</div>
          </div>
        </div>
      </CSSTransition>
    </Portal>
  );
};
