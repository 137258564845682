import { IPackageSize } from "../types/api-service";

export function numberWithSpaces(x: number) {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export function getPackageSizeName(size: IPackageSize) {
  let sizeName = '';

  if (size.ml) {
    sizeName = `${size.ml}ml`;
  } else if (size.grams) {
    sizeName = `${size.grams}g`;
  }

  const dimensions = [
    size.width && `${size.width}`,
    size.height && ` x ${size.height}`,
    size.depth && ` x ${size.depth}`,
  ].filter(Boolean).join(' ');

  if (sizeName) {
    return `${sizeName} (${dimensions} mm)`;
  }
  return `${dimensions} mm`;
}

export function getFormattedPercent(percent: number) {
  return percent * 100;
}
