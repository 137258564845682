import React, { useEffect } from "react";

import download from "downloadjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import { ReactComponent as InfoIconSvg } from "../../assets/images/icons/info-icon.svg";
import { ReactComponent as PdfIconSvg } from "../../assets/images/icons/pdf-icon.svg";
import useDesignTemplate from "../../hooks/useDesignTemplate";
import { selectProductStepData } from "../../store/wizard/wizardSlice";
import { IQuotation } from "../../types/api-service";
import { Button } from "../Button/Button";

interface Props {
  quotationData?: IQuotation;
}

export const DownloadDesignTemplate: React.FC<Props> = ({ quotationData }) => {
  const { t } = useTranslation(["global"]);
  const productStepData = useSelector(selectProductStepData);
  const { showButton, templateFilePath } = useDesignTemplate(quotationData || productStepData);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [showButton]);

  return showButton ? (
    <Button
      onClick={() => {
        download(templateFilePath as string);
        // ApiService.getTemplateDesignFile().then(() => null);
      }}
      variant="link"
    >
      <PdfIconSvg /> <span className="text-underline font-padding">{t("wizard.download_design_template")}</span>
      <InfoIconSvg data-tip={t("wizard.download_design_template_tooltip")} />
    </Button>
  ) : (
    <></>
  );
};
