import React from "react";

import ReactSelect from "react-select";

interface Props {
  options: {
    value: string;
    label: string;
  }[];
  placeholder?: string;
  value?: string;
  onChange?: (data: { value: string; label: string }) => void;
}

export const Select: React.FC<Props> = ({ options, onChange, value, placeholder }) => {
  return (
    <ReactSelect
      onChange={(data) => {
        if (onChange) {
          onChange(data as any);
        }
      }}
      options={options}
      placeholder={placeholder}
      value={options.find((e) => e.value === value)}
      className="select-form-control"
      classNamePrefix="select-form-control"
    />
  );
};
