import { ProductOptionTab } from "../../types/product-step";
import { WizardStepStatus, WizardStepType } from "../../types/wizard";
import { WizardState } from "../../types/wizard-state";

export const wizardInitialState: WizardState = {
  version: "4",
  progress: {
    [WizardStepType.Product]: WizardStepStatus.InProgress,
    [WizardStepType.Order]: WizardStepStatus.NotStarted,
    [WizardStepType.Design]: WizardStepStatus.NotStarted
  },
  stepsData: {
    [WizardStepType.Product]: {
      tabProgress: {
        [ProductOptionTab.Type]: false,
        [ProductOptionTab.Material]: false,
        [ProductOptionTab.Size]: false,
        [ProductOptionTab.Options]: false
      },
      values: {
        type: "",
        typeCode: "",
        activeOptionTab: ProductOptionTab.Type,
        options: {
          type: "",
          typeCode: "",
          subType: "",
          subTypeCode: "",
          material: "",
          size: "",
          sizeData: undefined,
          options: [],
          designAmount: "-",
          quantity: 0
        }
      }
    },
    [WizardStepType.Design]: {
      values: {}
    },
    [WizardStepType.Order]: {
      values: {
        options: {
          countryCode: "",
          customerToken: window.localStorage.getItem("token") ?? "",
          orderToken: "",
          vatNr: ""
        }
      }
    }
  },
  calculationData: {
    loader: false,
    isSuccess: true,
    data: null
  },
  apiData: {
    loader: true,
    data: null
  },
  quotationData: {
    loader: false,
    data: null
  }
};
