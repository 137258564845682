import React, { ForwardedRef } from "react";

interface Props {
  children: any;
}
export const ProductOptionContent: React.FC<Props & { ref?: React.Ref<HTMLDivElement> }> = React.forwardRef(
  ({ children }, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div className="product-step__options__content" ref={ref}>
        {children}
      </div>
    );
  }
);
