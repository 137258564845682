import { useEffect } from "react";

function useOutsideAlerter(ref: any, cb: () => void, additionalCondition?: (event: any) => boolean) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (additionalCondition) {
          if (additionalCondition(event)) {
            cb();
          }
        } else {
          cb();
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, cb]);
}

export default useOutsideAlerter;
