import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { PageHandler } from "../pages";
import { routes } from "./routes";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<PageHandler />} />
        {routes.map((item, idx) => (
          <Route path={item.path} key={idx}>
            {item.items.map((subItem, subItemIdx) => (
              <Route key={subItemIdx} index={subItem.isIndex} path={subItem.path} element={subItem.element} />
            ))}
          </Route>
        ))}
      </Routes>
    </BrowserRouter>
  );
};
