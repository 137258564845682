import React, { useEffect } from "react";

import ReactTooltip from "react-tooltip";

interface Props {
  children: any;
}

export const FormGroupLabel: React.FC<Props> = ({ children }) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return <div className="form-group__label">{children}</div>;
};
