import { useCallback, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { selectWizardProgress, setWizardProgress } from "../store/wizard/wizardSlice";
import { WizardStepStatus, WizardStepType } from "../types/wizard";
import { getRouteBasedOnProgress } from "../utilities/router";

const navigationMap = [WizardStepType.Product, WizardStepType.Order, WizardStepType.Design];

export const useWizardNavigation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wizardProgress = useSelector(selectWizardProgress);

  const currentStep: WizardStepType = useMemo(() => {
    return Number(
      Object.keys(wizardProgress).find(
        (step) => wizardProgress[Number(step) as WizardStepType] === WizardStepStatus.InProgress
      )
    ) as WizardStepType;
  }, [wizardProgress]);

  const goToNextStep = useCallback(() => {
    const indexInMap = navigationMap.indexOf(currentStep);
    const nextStep = navigationMap[indexInMap + 1];

    if (typeof nextStep === "undefined") return;

    dispatch(
      setWizardProgress({
        type: currentStep,
        status: WizardStepStatus.Completed
      })
    );

    dispatch(
      setWizardProgress({
        type: nextStep as WizardStepType,
        status: WizardStepStatus.InProgress
      })
    );

    // eslint-disable-next-line no-multi-assign
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    navigate(getRouteBasedOnProgress(navigationMap[currentStep] as WizardStepType));
  }, [dispatch, navigate, currentStep]);

  const goToPrevStep = useCallback(() => {
    const indexInMap = navigationMap.indexOf(currentStep);
    const prevStep = navigationMap[indexInMap - 1];
    if (typeof prevStep === "undefined") return;

    dispatch(
      setWizardProgress({
        type: currentStep,
        status: WizardStepStatus.NotStarted
      })
    );

    dispatch(
      setWizardProgress({
        type: prevStep as WizardStepType,
        status: WizardStepStatus.InProgress
      })
    );

    // eslint-disable-next-line no-multi-assign
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    navigate(getRouteBasedOnProgress(navigationMap[currentStep] as WizardStepType));
  }, [dispatch, navigate, currentStep]);

  return {
    goToNextStep,
    currentStep,
    goToPrevStep
  };
};
