import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Loader from "../components/common/Loader";
import { DesignOrderData } from "../components/Wizard/StepComponents/Design/DesignOrderData";
import { DesignOrderSummary } from "../components/Wizard/StepComponents/Design/DesignOrderSummary";
import { OrderImagePreview } from "../components/Wizard/StepComponents/Order/OrderImagePreview";
import { Wizard } from "../components/Wizard/Wizard";
import { WizardContent } from "../components/Wizard/WizardContent/WizardContent";
import { useWizardNavigation } from "../hooks/useWizardNavigation";
import { useWizardStepCheck } from "../hooks/useWizardStepCheck";
import { ApiService } from "../services/ApiService";
import { resetState, setWizardProgress } from "../store/wizard/wizardSlice";
import { IQuotation, IQuotationUpload } from "../types/api-service";
import { WizardStepStatus, WizardStepType } from "../types/wizard";

export const WizardDesignPage = () => {
  useWizardStepCheck();

  const { orderId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [showImageSizes, setImageShowSizes] = useState(false);
  const [quotationData, setQuotationData] = useState<IQuotation | null>(null);
  const { currentStep } = useWizardNavigation();

  const resetAndRedirect = () => {
    if (currentStep === WizardStepType.Design) {
      dispatch(resetState());
    }
    navigate("/");
  };

  useEffect(() => {
    const token = searchParams.get("token");
    if (!orderId || !token) {
      resetAndRedirect();
      return;
    }

    window.localStorage.setItem("orderToken", token);

    ApiService.getOrderData(orderId)
      .then((data) => {
        setQuotationData(data);
        dispatch(
          setWizardProgress({
            type: WizardStepType.Product,
            status: WizardStepStatus.Completed
          })
        );

        dispatch(
          setWizardProgress({
            type: WizardStepType.Order,
            status: WizardStepStatus.Completed
          })
        );

        dispatch(
          setWizardProgress({
            type: WizardStepType.Design,
            status: WizardStepStatus.InProgress
          })
        );
      })
      .catch(() => {
        resetAndRedirect();
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  return (
    <Wizard>
      <div className="product-step">
        <Loader show={loader} fullBlind />
        <WizardContent>
          {orderId && quotationData ? (
            <div className="row">
              <div className="col-lg-4 col-12">
                <OrderImagePreview
                  hideBackButton
                  hideDownloadTemplateButton
                  quotationData={quotationData}
                  showSizesOverwrite={showImageSizes}
                />
              </div>
              <div className="col-lg-4 col-12">
                <DesignOrderData
                  quotationData={quotationData}
                  onImageSizeShowChange={(val) => {
                    setImageShowSizes(val);
                  }}
                  unUploadsUpdate={(data) => {
                    setQuotationData((pv) => {
                      if (!pv) return pv;

                      return {
                        ...pv,
                        uploads: data
                      };
                    });
                  }}
                  onUploadDelete={(id) => {
                    setQuotationData((pv) => {
                      if (!pv) return pv;

                      return {
                        ...pv,
                        uploads: (pv.uploads as IQuotationUpload[]).filter((e) => e.uuid !== id)
                      };
                    });
                  }}
                />
              </div>
              <div className="col-lg-4 col-12">
                <DesignOrderSummary quotationData={quotationData} />
              </div>
            </div>
          ) : (
            ""
          )}
        </WizardContent>
      </div>
    </Wizard>
  );
};
