import React from "react";

import { useTranslation } from "react-i18next";

import { useWizardNavigation } from "../../../../hooks/useWizardNavigation";
import { Button } from "../../../Button/Button";
import { Icon } from "../../../common/Icon";

export const BackButton = () => {
  const { goToPrevStep } = useWizardNavigation();
  const { t } = useTranslation(["global"]);

  return (
    <Button
      onClick={() => {
        goToPrevStep();
      }}
      variant="link"
    >
      <Icon name="arrow-right reverse" /> <span className="font-padding">{t("wizard.back")}</span>
    </Button>
  );
};
