import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Loader from "../components/common/Loader";
import { ApiService } from "../services/ApiService";
import { selectSpecificStepDataValue } from "../store/wizard/wizardSlice";
import { WizardStepType } from "../types/wizard";

export const PaymentSuccess = () => {
  const { paymentId } = useParams();
  const orderToken = useSelector(selectSpecificStepDataValue(WizardStepType.Order, "options", "orderToken"));
  const navigate = useNavigate();

  useEffect(() => {
    if (!paymentId || !orderToken) {
      return;
    }
    ApiService.handleSuccessPayment(paymentId).then((data) => {
      navigate(`/design/${data.quotation.uuid}?token=${orderToken}`);
    });
  }, [paymentId, orderToken]);

  return (
    <div>
      <Loader show fullBlind />
    </div>
  );
};
