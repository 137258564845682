import { instanceOfQuotation, IQuotation } from "../types/api-service";
import { WizardStepType } from "../types/wizard";
import { WizardStepsData } from "../types/wizard-state";

export class WizardDataHelper {
  static getProductTypeCodeFromData = (data: WizardStepsData[WizardStepType.Product] | IQuotation) => {
    if (instanceOfQuotation(data)) {
      return data.product.code;
    }

    return data.values.typeCode;
  };

  static getPackagingTypeCodeFromData = (data: WizardStepsData[WizardStepType.Product] | IQuotation) => {
    if (instanceOfQuotation(data)) {
      return data.packagingType?.code;
    }

    return data.values.options.typeCode;
  };

  static getMaterialTypeCodeFromData = (data: WizardStepsData[WizardStepType.Product] | IQuotation) => {
    if (instanceOfQuotation(data)) {
      return data.materialVariation?.code || "material_variation.black";
    }

    return data.values.options.subTypeCode;
  };

  static getSizeDataFromData = (
    data: WizardStepsData[WizardStepType.Product] | IQuotation
  ): [number, number, number, number, number] => {
    if (instanceOfQuotation(data)) {
      return [
        data.packageSize.width,
        data.packageSize.height,
        data.packageSize.depth,
        data.packageSize.grams,
        data.packageSize.ml
      ];
    }

    return data.values.options.sizeData as [number, number, number, number, number];
  };

  static getFormattedMaterialVariationCode = (materialCode: "black" | "craft" | "white") => {
    const materialMap = {
      black: "Black",
      craft: "Craft",
      white: "White"
    };

    return materialMap[materialCode] as "Black" | "Craft" | "White";
  };

  static getPackagingTypeCodeValue = (typeCode: string) => {
    return typeCode.split("packaging_type.")[1] as "label" | "label_2" | "stock" | "printed" | "screen";
  };

  static getProductTypeCodeValue = (productCode: string) => {
    const productCodeParts = productCode.split(".");
    return productCodeParts.length > 1 ? productCodeParts[1] : '';
  };

  static getMaterialVariationTypeCodeValue = (materialVariationTypeCode: string) => {
    return materialVariationTypeCode.split("material_variation.")[1];
  };

  static getMaterialCode = (materialVariationCode: string) => {
    const materialCode = this.getMaterialVariationTypeCodeValue(materialVariationCode) as "black" | "craft" | "white";

    return this.getFormattedMaterialVariationCode(materialCode);
  };

  static getProductType = (productCode: string) => {
    const productTypes = ["doypack", "flat_bottom", "gusset", "sample", "stickers"];
    let productType = this.getProductTypeCodeValue(productCode);
    if (!productTypes.includes(productType)) {
      productType = "doypack"; // fallback to doypack
    }

    return productType;
  };
}
