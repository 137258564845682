import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import intervalPlural from "i18next-intervalplural-postprocessor";
import { initReactI18next } from "react-i18next";

import globalEn from "./dictionary/en/global.json";
import globalLv from "./dictionary/lv/global.json";

const options = {
  order: ["querystring"],
  lookupQuerystring: "lng"
};

export const resources = {
  lv: {
    global: globalLv
  },
  en: {
    global: globalEn
  }
} as const;

i18n
  .use(LanguageDetector)
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    // lng: "ru",
    detection: options,
    supportedLngs: ["en", "lv"],
    ns: ["global"],
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    resources
  });

export default i18n;
