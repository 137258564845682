import React from "react";

import classNames from "classnames";

interface Props {
  children: any;
  isAddon?: boolean;
}

export const FormGroupAppendix: React.FC<Props> = ({ children, isAddon }) => {
  return (
    <div
      className={classNames("form-group__appendix", {
        "form-group__appendix--addon": isAddon
      })}
    >
      {children}
    </div>
  );
};
