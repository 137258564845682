import React from "react";

import classNames from "classnames";

interface Props {
  variant?: "orange" | "green" | "border-orange-with-arrow" | "border-green" | "link" | string;
  size?: "sm" | "lg" | "md";
  type?: "button" | "submit" | "reset";
  onClick: () => void;
  children: any;
  disabled?: boolean;
  className?: string;
}

export const Button: React.FC<Props> = ({ children, className, variant, type, size, onClick, disabled }) => {
  return (
    <button
      disabled={disabled}
      type={type || "button"}
      onClick={onClick}
      className={classNames("btn", variant ? `btn-${variant}` : "", size ? `btn-${size}` : "", className)}
    >
      {children}
    </button>
  );
};
