export enum WizardStepType {
  Product,
  Order,
  Design
}

export enum WizardStepStatus {
  NotStarted,
  InProgress,
  Completed
}
