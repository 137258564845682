import React, { useCallback, useEffect, useState } from "react";

import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { ReactComponent as InfoIconSvg } from "../../../../assets/images/icons/info-icon.svg";
import { useAppDispatch } from "../../../../hooks/redux";
import { useHasCustomDesign } from "../../../../hooks/useHasCustomDesign";
import { selectSpecificStepDataValue, setSpecificStepDataValue } from "../../../../store/wizard/wizardSlice";
import { WizardStepType } from "../../../../types/wizard";
import { Alert } from "../../../common/Alert";
import Loader from "../../../common/Loader";
import { InputField } from "../../../FormGroup/InputField/InputField";
import { InputSlider } from "../../../FormGroup/InputSlider/InputSlider";

export interface QuantityRangeSettings {
  min: number | null;
  max: number | null;
}

interface Props {
  isDisabled: boolean;
  onChange: (data: { quantity: number; designAmount: number }) => void;
  quantityRanges: QuantityRangeSettings;
  onQuantityLimitExceeded: (isExceeded: boolean) => void;
  quantityRangeLoader: boolean;
}

export const ProductSummaryInputs: React.FC<Props> = ({
  isDisabled,
  onChange,
  quantityRanges,
  quantityRangeLoader,
  onQuantityLimitExceeded
}) => {
  const { t } = useTranslation(["global"]);
  const dispatch = useAppDispatch();
  const hasCustomDesign = useHasCustomDesign();

  const [isQuantityRangeLimitExceed, setIsQuantityRangeLimitExceed] = useState(false);
  const [quantityValue, setQuantityValue] = useState(0);

  const designAmountValue = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "designAmount"));
  const packagingTypeCode = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "typeCode"));
  const piecesCountValue = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "quantity"));

  const handleQuantityChange = useCallback(
    (value: string) => {
      if (!quantityRanges.min || !quantityRanges.max) return;

      const re = /^[0-9\b]+$/;

      if (value === "" || re.test(value)) {
        const numberValue = Number(value);
        setQuantityValue(numberValue);
      }
    },
    [dispatch, quantityRanges]
  );

  const handleDesignAmountChange = useCallback(
    (value: string) => {
      const re = /^[0-9\b]+$/;

      if (value === "" || re.test(value)) {
        dispatch(
          setSpecificStepDataValue({
            type: WizardStepType.Product,
            option: "options",
            subOption: "designAmount",
            value
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!quantityRanges.min || !quantityRanges.max) return;

    if (quantityValue < quantityRanges.min) {
      setQuantityValue(
        packagingTypeCode === "packaging_type.printed" ? 2000 : quantityRanges.max >= 1000 ? 1000 : quantityRanges.min
      );
    } else if (quantityValue > quantityRanges.max) {
      setQuantityValue(quantityRanges.max);
    }
  }, [quantityRanges]);

  useEffect(() => {
    if (!isDisabled && designAmountValue === "-") {
      dispatch(
        setSpecificStepDataValue({
          type: WizardStepType.Product,
          option: "options",
          subOption: "designAmount",
          value: "1"
        })
      );
    }
  }, [isDisabled]);

  useEffect(() => {
    if (!quantityRanges.min || !quantityRanges.max) return;

    if (quantityValue < quantityRanges.min || quantityValue > quantityRanges.max) {
      setIsQuantityRangeLimitExceed(true);
    } else {
      setIsQuantityRangeLimitExceed(false);
    }
  }, [quantityValue, quantityRanges]);

  const onChangeHandler = useCallback(
    (_designAmountValue: string, _quantityValue: number, _isQuantityRangeLimitExceed: boolean) => {
      if (_isQuantityRangeLimitExceed) {
        return;
      }
      setIsQuantityRangeLimitExceed(false);

      dispatch(
        setSpecificStepDataValue({
          type: WizardStepType.Product,
          option: "options",
          subOption: "quantity",
          value: _quantityValue
        })
      );

      onChange({
        quantity: _quantityValue,
        designAmount: Number(_designAmountValue)
      });
    },
    []
  );

  const onChangeDebounced = useCallback(debounce(onChangeHandler, 500), []);

  useEffect(() => {
    onChangeDebounced(designAmountValue, quantityValue, isQuantityRangeLimitExceed);
  }, [designAmountValue, quantityValue, isQuantityRangeLimitExceed]);

  useEffect(() => {
    if (piecesCountValue) {
      setQuantityValue(piecesCountValue);
    }
  }, []);

  useEffect(() => {
    onQuantityLimitExceeded(isQuantityRangeLimitExceed);
  }, [isQuantityRangeLimitExceed]);

  return (
    <>
      {packagingTypeCode !== "packaging_type.label" && hasCustomDesign ? (
        <InputField
          label={
            <span className="d-flex align-items-center">
              {t("wizard.steps.product.design_amount")}:
              <InfoIconSvg className="ml-1" data-tip={t("wizard.steps.product.design_amount_tooltip")} />
            </span>
          }
          type="text"
          value={designAmountValue}
          onChange={(e) => {
            handleDesignAmountChange(e.target.value);
          }}
        />
      ) : (
        ""
      )}

      <div className="position-relative mt-2">
        <Loader show={quantityRangeLoader} />
        <InputSlider
          label={`${t("wizard.steps.product.quantity_pieces")}:`}
          min={quantityRanges.min || 1}
          max={quantityRanges.max || 100}
          value={quantityValue}
          onChange={(e) => {
            handleQuantityChange(e.target.value);
          }}
        />

        <InputField
          className="mt-1"
          type="text"
          value={quantityValue.toString()}
          onChange={(e) => {
            handleQuantityChange(e.target.value);
          }}
        />

        {isQuantityRangeLimitExceed && quantityRanges.min && quantityRanges.max && (
          <div className="mt-2">
            <Alert variant="danger">
              {quantityValue > quantityRanges.max
                ? t("wizard.steps.product.exceeded_quantity_range_max_limit", { max: quantityRanges.max })
                : t("wizard.steps.product.exceeded_quantity_range_min_limit", { min: quantityRanges.min })}
            </Alert>
          </div>
        )}
      </div>
    </>
  );
};
