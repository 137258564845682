import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as InvoiceIconSvg } from "../../../../assets/images/icons/invoice.svg";
import { ReactComponent as MaestroCardIconSvg } from "../../../../assets/images/icons/maestro.svg";
import { ReactComponent as MasterCardIconSvg } from "../../../../assets/images/icons/mastercard.svg";
import { ReactComponent as VisaIconSvg } from "../../../../assets/images/icons/visa.svg";
import { useIsUserRegistered } from "../../../../hooks/useIsUserRegistered";
import { usePaymentControls } from "../../../../hooks/usePaymentControls";
import { ApiService } from "../../../../services/ApiService";
import { IQuotation } from "../../../../types/api-service";
import { Button } from "../../../Button/Button";
import Loader from "../../../common/Loader";
import { BlockTitle } from "../shared/BlockTitle";

interface Props {
  data: IQuotation | null;
  country: string;
  vatNr: string;
}

export const PaymentModalBody: React.FC<Props & React.HtmlHTMLAttributes<HTMLDivElement>> = (props) => {
  const { data, country, vatNr } = props;
  const navigate = useNavigate();
  const isUserRegistered = useIsUserRegistered();
  const { t } = useTranslation(["global"]);
  const { getAndSaveOrderToken, loader: paymentLoader } = usePaymentControls();
  const [invoiceLoader, setLoader] = useState(false);
  function proceedStripePayment() {
    if (!data) return;
    getAndSaveOrderToken(data.uuid, country, vatNr);
  }
  const proceedInvoicePayment = async () => {
    setLoader(true);
    document.body.classList.remove("prevent-scroll");
    if (isUserRegistered && data) {
      await ApiService.createInvoiceForRegisteredUser(data.uuid).then(() => {
        navigate(`/design/${data.uuid}?token=${data.orderToken}`);
      });
    } else {
      navigate(`/register`);
    }
  };

  return (
    <div className="order-step__payment">
      <BlockTitle title={t("wizard.steps.order.pay_choose")} />
      <div className="order-step__payment__actions">
        <div className="mt-1 order-step__payment__actions__button">
          <Button
            onClick={async () => {
              proceedStripePayment();
            }}
            variant="link-between"
          >
            <Loader show={paymentLoader} variant="small" />
            <span className="font-padding">{t("wizard.steps.order.pay_card")}</span>
            <span>
              <VisaIconSvg /> <MasterCardIconSvg /> <MaestroCardIconSvg />{" "}
            </span>
          </Button>
        </div>
        <div className="mt-1 order-step__payment__actions__button">
          <Button
            onClick={async () => {
              await proceedInvoicePayment();
            }}
            variant="link-between"
          >
            <Loader show={invoiceLoader} variant="small" />
            <span className="font-padding">{t("wizard.steps.order.pay_invoice")}</span>
            <InvoiceIconSvg />
          </Button>
        </div>
      </div>
    </div>
  );
};
