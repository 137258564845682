import React, { useCallback, useEffect, useRef, useState } from "react";

import classNames from "classnames";
import debounce from "lodash.debounce";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Carousel } from "react-responsive-carousel";

import { selectProductStepData } from "../../../../store/wizard/wizardSlice";
import { IQuotation } from "../../../../types/api-service";
import CarouselImageAssembler from "../../../../utilities/carouselImageAssembler";
// eslint-disable-next-line import/no-cycle
import { ViewPackageButton } from "../../ViewPackageButton";

interface Props {
  hidePackageButton?: boolean;
  showSizesOverwrite?: boolean;
  quotationData?: IQuotation;
}

export const ImageCarousel: React.FC<Props> = ({ hidePackageButton, showSizesOverwrite, quotationData }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const productStepData = useSelector(selectProductStepData);

  const [dynamicImages, setDynamicImages] = useState<string[]>([]);
  const [imageSizes, setImageSizes] = useState<[number, number, number, number, number] | null>(null);
  const [transitionTime, setTransitionTime] = useState(200);
  const carouselRef = useRef<any>(null);

  const [renderCount, setRenderCount] = useState(0);

  const moveCarouselTo = useCallback(
    (index: number) => {
      if (!carouselRef.current) return;
      setTransitionTime(0);

      if (renderCount === 0) {
        setTimeout(() => {
          carouselRef.current.moveTo(index);
        }, 80);
      } else {
        carouselRef.current.moveTo(index);
      }

      setRenderCount((pv) => pv + 1);

      setTimeout(() => {
        setTransitionTime(200);
      }, 100);
    },
    [carouselRef, renderCount]
  );

  const moveCarouselToDebounced = useCallback(
    debounce(moveCarouselTo, 200, {
      leading: true,
      trailing: false
    }),
    [renderCount]
  );

  useEffect(() => {
    const {
      images: carouselImages,
      sizes,
      shouldMoveCarousel
    } = CarouselImageAssembler.assembleCarouselImageList(quotationData || productStepData, showSizesOverwrite);

    const activeIndex = carouselImages.findIndex((e) => e.isActive);
    setDynamicImages(carouselImages.map((e) => e.path));
    setImageSizes(sizes || null);

    if (shouldMoveCarousel) {
      const newCurrentSlide = activeIndex === -1 ? 0 : activeIndex;
      moveCarouselToDebounced(newCurrentSlide);
    }
  }, [quotationData, productStepData, showSizesOverwrite]);

  useEffect(() => {
    if (carouselRef.current) {
      moveCarouselToDebounced(0);
    }
  }, [carouselRef]);

  useEffect(() => {
    moveCarouselToDebounced(0);
  }, [productStepData.values.options.type]);

  const { ref, inView } = useInView({
    threshold: 0
  });

  return (
    <div
      className={classNames("wizard__content__image-carousel", {
        "wizard__content__image-carousel--inner-padding":
          productStepData.values.typeCode.includes("gusset") || productStepData.values.typeCode.includes("flat_bottom")
      })}
      ref={ref}
    >
      <Carousel
        ref={carouselRef}
        showThumbs={false}
        showIndicators={false}
        transitionTime={transitionTime}
        showArrows
        showStatus={false}
        infiniteLoop
      >
        {dynamicImages.map((src, idx) => (
          <div key={idx}>
            {imageSizes ? (
              <div
                className={classNames("product-size-mask", {
                  "product-size-mask--flat":
                    productStepData.values.typeCode.includes("gusset") ||
                    productStepData.values.typeCode.includes("flat_bottom"),
                  "product-size-mask--stickers":
                    productStepData.values.typeCode.includes("stickers"),
                  "product-size-mask--label":
                    productStepData.values.typeCode.includes("flat_bottom") &&
                    productStepData.values.options.typeCode.includes("label")
                })}
              >
                <span className="product-size-mask__item product-size-mask__item--width">{imageSizes[0]}</span>
                <span className="product-size-mask__item product-size-mask__item--height">{imageSizes[1]}</span>
                {productStepData.values.typeCode.includes("stickers") ? ("") : (
                <span className="product-size-mask__item product-size-mask__item--depth">{imageSizes[2]}</span>
                )}
              </div>
            ) : (
              ""
            )}
            <img src={src} alt="abc" />
          </div>
        ))}
      </Carousel>

      {!hidePackageButton && isMobile && !inView ? <ViewPackageButton /> : ""}
    </div>
  );
};
