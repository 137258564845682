import React from "react";

import classNames from "classnames";

interface Props {
  title?: string;
  className?: string;
  children: any;
}

export const FooterCol: React.FC<Props> = ({ title, children, className }) => {
  return (
    <div className={classNames("footer__col", className)}>
      {title ? <div className="footer__col__title">{title}</div> : ""}
      {children}
    </div>
  );
};
