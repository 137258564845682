import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import localization from "../../i18n/config";
import CookiesService from "../../services/CookiesService";
import type { RootState } from "../store";

interface AppState {
  selectedLanguage: string;
}

const initialState: AppState = {
  selectedLanguage: CookiesService.get("lng") || localization.language || "en"
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSelectedLanguage: (state: AppState, action: PayloadAction<string>) => {
      state.selectedLanguage = action.payload;
    }
  }
});

export const { setSelectedLanguage } = appSlice.actions;

export const selectSelectedLanguage = (state: RootState) => state.app.selectedLanguage;

export default appSlice.reducer;
