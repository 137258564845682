import React, { useMemo } from "react";

import classNames from "classnames";

import { ReactComponent as CheckmarkSvg } from "../../../assets/images/icons/check-icon.svg";
import { WizardStepStatus } from "../../../types/wizard";

interface Props {
  keyValue: number;
  title: string;
  state: WizardStepStatus;
  colSize?: number;
}

export const WizardStep: React.FC<Props> = ({ keyValue, title, state, colSize }) => {
  const statusClassName = useMemo(() => {
    switch (state) {
      case WizardStepStatus.Completed:
        return "completed";
      case WizardStepStatus.InProgress:
        return "in-progress";
      case WizardStepStatus.NotStarted:
        return "not-started";
      default:
        return "";
    }
  }, [state]);

  return (
    <div className={`wizard__step__wrapper col-${colSize || 2}`}>
      <div className={classNames("wizard__step", `wizard__step--${statusClassName}`)}>
        <div className="wizard__step__key">{state === WizardStepStatus.Completed ? <CheckmarkSvg /> : keyValue}</div>
        <div className="wizard__step__title">{title}</div>
      </div>
    </div>
  );
};
