import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ApiService } from "../../../../services/ApiService";
import {
  selectSpecificStepDataValue,
  selectSummaryData,
  selectWizardCalculationData,
  selectWizardQuotationData,
  setSpecificStepDataValue
} from "../../../../store/wizard/wizardSlice";
import { ICountry } from "../../../../types/api-service";
import { WizardStepType } from "../../../../types/wizard";
import { Select } from "../../../Select/Select";
import { OrderProductSummary } from "../shared/OrderProductSummary";
import { VatNumber } from "./VatNumber";

interface Props {
  onImageSizeShowChange: (show: boolean) => void;
}

export const OrderData: React.FC<Props> = ({ onImageSizeShowChange }) => {
  const { t } = useTranslation(["global"]);
  const dispatch = useDispatch();
  const summaryData = useSelector(selectSummaryData);
  const { data: calculationData } = useSelector(selectWizardCalculationData);
  const { data: quotationData } = useSelector(selectWizardQuotationData);
  const designAmountValue = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "designAmount"));
  const piecesCountValue = useSelector(selectSpecificStepDataValue(WizardStepType.Product, "options", "quantity"));
  const selectedCountry = useSelector(selectSpecificStepDataValue(WizardStepType.Order, "options", "countryCode"));
  const customerToken = localStorage.getItem("token");

  const [countryList, setCountryList] = useState<ICountry[]>([]);

  useEffect(() => {
    ApiService.getCountryList().then((data) => {
      setCountryList(data);
    });
  }, []);

  return (
    <div className="order-step__data right-border">
      <OrderProductSummary
        summaryData={summaryData}
        designAmountValue={Number(designAmountValue)}
        piecesCountValue={piecesCountValue}
        unitPrice={calculationData?.unitPrice}
        onSizeShow={onImageSizeShowChange}
      />
      <div className="mt-3">
        <Select
          value={selectedCountry}
          onChange={(data) => {
            dispatch(
              setSpecificStepDataValue({
                type: WizardStepType.Order,
                option: "options",
                subOption: "countryCode",
                value: data.value
              })
            );
          }}
          placeholder={t("wizard.steps.order.delivery_country")}
          options={countryList.map((e) => ({ value: e.code, label: e.name }))}
        />
      </div>
      {!customerToken && quotationData ? (
        <div className="mt-3">
          <VatNumber
            quotationId={quotationData.uuid}
            quotationToken={quotationData.orderToken}
            selectedCountry={selectedCountry}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
