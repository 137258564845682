import React, { useEffect, useMemo } from "react";

import classNames from "classnames";
import ReactTooltip from "react-tooltip";

import { randomId } from "../../../utilities/random";
import { FormGroup } from "../FormGroup";
import { FormGroupTooltip } from "../FormGroupTooltip";

interface Props {
  items: {
    value: string;
    text: any;
    subText?: any;
    tooltip?: string;
    childrenWhenChecked?: any;
  }[];
  name: string;
  value: string;
  itemSeparator?: boolean;
  inline?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioButtons: React.FC<Props> = ({ items, value, inline, onChange, name, itemSeparator }) => {
  const id = useMemo(() => randomId(), []);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [items]);

  return (
    <FormGroup>
      <div
        className={classNames("radio-buttons", {
          "radio-buttons--with-separator": itemSeparator,
          "radio-buttons--inline": inline
        })}
      >
        {items.map(({ value: itemValue, text, subText, tooltip, childrenWhenChecked }, idx) => {
          const itemId = `${id}_${idx}`;
          return (
            <div className="radio-container" key={idx}>
              <div className="input-group">
                <input
                  name={name}
                  type="radio"
                  className="form-control"
                  value={itemValue}
                  id={itemId}
                  checked={value === itemValue}
                  onChange={onChange}
                />{" "}
                <label htmlFor={itemId}>
                  {subText ? (
                    <div className="radio-container__label-with-sub font-padding">
                      <span>{text}</span>
                      <span>{subText}</span>
                    </div>
                  ) : (
                    <span className="nowrap font-padding">{text}</span>
                  )}
                </label>
                {tooltip ? <FormGroupTooltip tooltip={tooltip} /> : ""}
              </div>
              {childrenWhenChecked && value === itemValue ? (
                <div className="radio-container__appendix">{childrenWhenChecked}</div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
    </FormGroup>
  );
};
