import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { selectWizardProgress, selectWizardQuotationData } from "../store/wizard/wizardSlice";
import { WizardStepStatus, WizardStepType } from "../types/wizard";
import { getRouteBasedOnProgress } from "../utilities/router";

export const useWizardStepCheck = () => {
  const wizardProgress = useSelector(selectWizardProgress);
  const location = useLocation();
  const navigate = useNavigate();
  const { data: quotationData } = useSelector(selectWizardQuotationData);

  useEffect(() => {
    const activeStepIndex = Object.keys(wizardProgress).find(
      (step) => wizardProgress[Number(step) as WizardStepType] === WizardStepStatus.InProgress
    );

    const path = getRouteBasedOnProgress(Number(activeStepIndex) as WizardStepType);

    // const formattedPath = path === "/design" && quotationData ? `/design/${quotationData.uuid}` : path;

    if (!location.pathname.startsWith("/design") && location.pathname !== path) {
      navigate(path);
    }
  }, [wizardProgress, quotationData]);
};
