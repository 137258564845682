import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ApiService } from "../../services/ApiService";
import { LocalStorageService } from "../../services/LocalStorageService";
import { IConstructorData, SaveOrderParams } from "../../types/api-service";
import { ProductOptionTab } from "../../types/product-step";
import { WizardStepStatus, WizardStepType } from "../../types/wizard";
import {
  ISummaryData,
  SelectSpecificStepDataValueOverload,
  WizardApiData,
  WizardState,
  WizardStepsData
} from "../../types/wizard-state";
import { getPackageSizeName } from "../../utilities/formatter";
import { getTranslationFromLangObj } from "../../utilities/language";
import type { RootState } from "../store";
import { wizardInitialState } from "./initialState";

const initialState: WizardState = wizardInitialState;

// --------- thunks
export const fetchConstructorData = createAsyncThunk("wizard/fetchConstructorData", () =>
  ApiService.getConstructorData()
);

export const fetchCalculationData = createAsyncThunk(
  "wizard/fetchCalculationData",
  async (payload: {
    materialId: string | undefined;
    packageSizeId: string | undefined;
    quantity: number | undefined;
    designAmount: number | undefined;
    productId: string | undefined;
    packageTypeId: string | undefined;
    materialVariationId: string | undefined;
    selectedOptionsIds: string[] | undefined;
  }) =>
    ApiService.calculateSummary(
      payload.materialId,
      payload.packageSizeId,
      payload.quantity,
      payload.designAmount,
      payload.productId,
      payload.packageTypeId,
      payload.materialVariationId,
      payload.selectedOptionsIds
    )
);

export const saveOrder = createAsyncThunk("wizard/saveOrder", (payload: SaveOrderParams) =>
  ApiService.saveOrder(payload)
);

// --------- custom reducers
export function setSpecificStepDataValue<
  K extends WizardStepType,
  T extends keyof WizardStepsData[K]["values"],
  S extends keyof WizardStepsData[K]["values"][T]
>(value: {
  type: K;
  option: T;
  subOption?: S;
  value: WizardStepsData[K]["values"][T][S] | WizardStepsData[K]["values"][T];
}): PayloadAction<{
  type: K;
  option: T;
  subOption?: S;
  value: WizardStepsData[K]["values"][T][S] | WizardStepsData[K]["values"][T];
}> {
  return {
    type: "setSpecificStepDataValue",
    payload: value
  };
}

setSpecificStepDataValue.type = "setSpecificStepDataValue";

// --------- custom reducers

export const wizardSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setWizardProgress: (
      state: WizardState,
      action: PayloadAction<{
        type: WizardStepType;
        status: WizardStepStatus;
      }>
    ) => {
      state.progress[action.payload.type] = action.payload.status;
    },

    resetQuotationData: (state: WizardState) => {
      state.quotationData = initialState.quotationData;
    },

    resetState: (state: WizardState) => {
      state.quotationData = initialState.quotationData;
      state.calculationData = initialState.calculationData;
      state.stepsData = initialState.stepsData;
      state.progress = initialState.progress;
    },

    resetProductStepProgress: (
      state: WizardState,
      action: PayloadAction<
        | {
            force: boolean;
          }
        | undefined
      >
    ) => {
      state.stepsData[WizardStepType.Product].values.activeOptionTab = ProductOptionTab.Type;
      state.stepsData[WizardStepType.Product].tabProgress = {
        [ProductOptionTab.Type]: !action.payload?.force,
        [ProductOptionTab.Material]: false,
        [ProductOptionTab.Size]: false,
        [ProductOptionTab.Options]: false
      };

      state.stepsData[WizardStepType.Product].values.options = {
        ...(action.payload?.force
          ? initialState.stepsData[WizardStepType.Product].values.options
          : {
              ...state.stepsData[WizardStepType.Product].values.options,
              material: "",
              size: "",
              options: []
            })
      };
    },

    setProductStepProgress: (
      state: WizardState,
      action: PayloadAction<{
        type: ProductOptionTab;
        status: boolean;
      }>
    ) => {
      state.stepsData[WizardStepType.Product].tabProgress[action.payload.type] = action.payload.status;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSpecificStepDataValue, (state, action) => {
        const { type, option, value, subOption } = action.payload;

        if (subOption) {
          state.stepsData[type as WizardStepType].values[option][subOption] = value;
        } else {
          state.stepsData[type].values[option] = value;
        }
      })
      .addCase(fetchConstructorData.fulfilled, (state, action) => {
        state.apiData.loader = false;
        state.apiData.data = action.payload;
      })

      .addCase(fetchCalculationData.pending, (state) => {
        state.calculationData.loader = true;
      })
      .addCase(fetchCalculationData.fulfilled, (state, action) => {
        state.calculationData.data = action.payload;
        state.calculationData.loader = false;
      })
      .addCase(fetchCalculationData.rejected, (state, action) => {
        if (action.error.code) {
          switch (action.error.code) {
            case "ERR_BAD_REQUEST":
              LocalStorageService.reset();
              wizardSlice.caseReducers.resetState(state);
              window.location.reload();
              break;
            default:
          }
        }
      })
      .addCase(saveOrder.pending, (state) => {
        state.quotationData.loader = true;
      })
      .addCase(saveOrder.fulfilled, (state, action) => {
        state.quotationData.data = action.payload;
        state.quotationData.loader = false;
        state.calculationData.isSuccess = true;
      })
      .addCase(saveOrder.rejected, (state, action) => {
        // if (action.error.code) {
        //   switch (action.error.code) {
        //     case "ERR_BAD_REQUEST":
        //       state.quotationData.loader = false;
        //       state.calculationData.isSuccess = false;
        //       break;
        //     default:
        //   }
        // }
      });
  }
});

export const { setWizardProgress, setProductStepProgress, resetQuotationData, resetState, resetProductStepProgress } =
  wizardSlice.actions;

// --------- selectors

export const selectWizardProgress = (state: RootState) => state.wizard.progress;
export const selectWizardApiDataLoader = (state: RootState) => state.wizard.apiData.loader;
export const selectSpecificWizardApiDataValue =
  <T extends keyof IConstructorData>(key: T) =>
  (state: RootState) => {
    const values = state.wizard.apiData.data as WizardApiData["data"];

    if (values === null) {
      return null;
    }

    return values[key];
  };
export const selectWizardState = (state: RootState) => state.wizard;
export const selectWizardCalculationData = (state: RootState) => state.wizard.calculationData;
export const selectWizardQuotationData = (state: RootState) => state.wizard.quotationData;

export const selectSpecificStepDataValue: SelectSpecificStepDataValueOverload<RootState> =
  <
    K extends WizardStepType,
    T extends keyof WizardStepsData[K]["values"],
    S extends keyof WizardStepsData[K]["values"][T]
  >(
    stepType: K,
    key: T,
    subKey?: S
  ) =>
  (state: RootState) => {
    const values = state.wizard.stepsData[stepType].values as WizardStepsData[K]["values"];

    if (subKey) {
      return values[key][subKey];
    }

    return values[key];
  };

export const selectSpecificStepModelValue =
  <K extends WizardStepType, T extends keyof WizardStepsData[K]>(stepType: K, key: T) =>
  (state: RootState) => {
    const values = state.wizard.stepsData[stepType] as WizardStepsData[K];

    return values[key];
  };

export const selectProductMaterialsBasedOnSelectedType = (state: RootState) => {
  const apiData = state.wizard.apiData.data;
  const { subType, type } = state.wizard.stepsData[WizardStepType.Product].values.options;
  const { typeCode } = state.wizard.stepsData[WizardStepType.Product].values;

  if (apiData === null) return [];
  if (!type) return [];

  const selectedTypeTarget = apiData.packagingTypes.find((e) => e.uuid === type);

  if (!selectedTypeTarget) return [];

  let availableMaterials =
    apiData.products.find((product) => product.code === typeCode)?.packagingTypes.find((e) => e.uuid === type)?.materials ||
    apiData.materials;

  if (selectedTypeTarget.isMaterialVariations) {
    if (!subType) return [];
    // todo update backend!
    availableMaterials =
      apiData.materialVariations.find((materialVariation) => materialVariation.uuid === subType)?.materials || [];
    if (typeCode !== "pouch_type.doypack") {
      availableMaterials = availableMaterials.filter(
        (el: { code: string }) => el.code !== "material.brown_craft_paper"
      );
    }
  }

  return availableMaterials;
};

export const selectPouchOptionsBasedOnSelectedProduct = (state: RootState) => {
  const apiData = state.wizard.apiData.data;
  const { options, type } = state.wizard.stepsData[WizardStepType.Product].values;
  if (apiData === null) return [];
  if (!type) return [];

  const selectedTypeTarget = apiData.packagingTypes.find((e) => e.uuid === options.type);

  if (!selectedTypeTarget) return [];

  let availableOptions =
    apiData.products.find((product) => product.uuid === type)?.packagingTypes.find((e) => e.uuid === options.type)
      ?.pouchOptions || [];

  if (selectedTypeTarget.code === "packaging_type.printed") {
    switch (apiData.materials.find((material) => material.uuid === options.material)?.code) {
      case "material.recyclable_paper":
        availableOptions = availableOptions.filter(
          (el: { code: string }) => el.code !== "pouch_option.valve" && el.code !== "pouch_option.inside_zipper"
        );
        break;
      case "material.brown_craft_paper":
        availableOptions = availableOptions.filter((el: { code: string }) => el.code !== "pouch_option.valve");
        break;
      default:
    }
  }
  return availableOptions;
};

export const selectSizesBasedOnSelectedProduct = (state: RootState) => {
  const apiData = state.wizard.apiData.data;
  const { options, type } = state.wizard.stepsData[WizardStepType.Product].values;
  if (apiData === null) return [];
  if (!type) return [];

  const selectedTypeTarget = apiData.packagingTypes.find((e) => e.uuid === options.type);

  if (!selectedTypeTarget) return [];
  return (
    apiData.products.find((product) => product.uuid === type)?.packagingTypes.find((e) => e.uuid === options.type)
      ?.packageSizes || []
  );
};

export const selectPackagingTypesBasedOnSelectedProduct = (state: RootState) => {
  const apiData = state.wizard.apiData.data;
  const { type } = state.wizard.stepsData[WizardStepType.Product].values;
  if (apiData === null) return [];
  if (!type) return [];

  return apiData.products.find((product) => product.uuid === type)?.packagingTypes || [];
};

export const selectPackagingTypeData = (packageTypeId?: string) => (state: RootState) => {
  const apiData = state.wizard.apiData.data;

  let type = packageTypeId || "";
  if (apiData === null) return null;

  if (!packageTypeId) {
    const activeStep = Number(
      Object.keys(state.wizard.progress).filter(
        (key) => state.wizard.progress[Number(key) as WizardStepType] === WizardStepStatus.InProgress
      )[0]
    ) as WizardStepType;

    if (activeStep !== WizardStepType.Design) {
      type = state.wizard.stepsData[WizardStepType.Product].values.options.type;
    } else if (state.wizard.quotationData.data && state.wizard.quotationData.data.packagingType) {
      type = state.wizard.quotationData.data.packagingType.uuid;
    }
  }

  if (!type) return null;

  return apiData.packagingTypes.find((e) => e.uuid === type);
};

export const isProductSummaryDisabled = (state: RootState) => {
  const { values } = state.wizard.stepsData[WizardStepType.Product];
  if (values.typeCode === "pouch_type.sample") {
    return false;
  }
  return !(
    values.type &&
    values.options.type &&
    // values.options.options.length &&
    values.options.material &&
    values.options.size
  );
};

export const isSampleSetSelected = (state: RootState) => {
  return state.wizard.stepsData[WizardStepType.Product].values.typeCode === "pouch_type.sample";
};

export const isProductOptionsDisabled = (state: RootState) => {
  const { values } = state.wizard.stepsData[WizardStepType.Product];

  return !values.type;
};

export const selectProductStepData = (state: RootState) => {
  return state.wizard.stepsData[WizardStepType.Product];
};

export const selectProductStepValues = (state: RootState) =>
  state.wizard.stepsData[WizardStepType.Product].values.options;
export const selectProductStepType = (state: RootState) => state.wizard.stepsData[WizardStepType.Product].values.type;
export const selectWizardApiData = (state: RootState) => state.wizard.apiData.data;
export const selectSelectedTypeData = (state: RootState) => {
  if (state.wizard.apiData.data === null) return null;

  const selectedTypeId = state.wizard.stepsData[WizardStepType.Product].values.options.type;

  if (!selectedTypeId) return null;

  return state.wizard.apiData.data.packagingTypes.find((e) => e.uuid === selectedTypeId);
};

export const selectSummaryData = createSelector(
  [selectProductStepValues, selectProductStepType, selectWizardApiData, selectPackagingTypeData()],
  (options, product, apiData, packagingTypeData) => {
    if (!apiData) return null;

    const data: any = {};
    const productTarget = apiData.products.find((e) => e.uuid === product);
    if (productTarget) {
      data.product = {
        id: productTarget.uuid,
        name: productTarget.code
      };
      if (productTarget.code === "pouch_type.sample") {
        data.designAmount = 0;
        return data as ISummaryData;
      }
    }
    const {
      material: selectedMaterial,
      size: selectedSize,
      options: selectedOptions,
      subType: materialVariation,
      type: packagingType,
      designAmount,
      quantity
    } = options;

    if (
      !selectedMaterial ||
      // !selectedOptions.length ||
      !selectedSize
    ) {
      return null;
    }

    const materialTarget = apiData.materials.find((e) => e.uuid === selectedMaterial);
    if (materialTarget) {
      data.material = {
        id: materialTarget.uuid,
        name: getTranslationFromLangObj(materialTarget.nameTrans)
      };
    }

    const materialVariationTarget = apiData.materialVariations.find((e) => e.uuid === materialVariation);
    if (materialVariationTarget) {
      data.materialVariation = {
        id: materialVariationTarget.uuid,
        name: materialVariationTarget.code
      };
    }

    const packagingTypeTarget = apiData.packagingTypes.find((e) => e.uuid === packagingType);
    if (packagingTypeTarget) {
      data.packagingType = {
        id: packagingTypeTarget.uuid,
        name: packagingTypeTarget.code
      };
    }

    if (productTarget) {
      const sizeTarget =
        productTarget.code === "pouch_type.doypack"
          ? productTarget.packagingTypes
              .find((e) => e.uuid === packagingType)
              ?.packageSizes.find((e) => e.uuid === selectedSize)
          : apiData.packageSizes.find((e) => e.uuid === selectedSize);
      if (sizeTarget) {
        data.size = {
          id: sizeTarget.uuid,
          name: getPackageSizeName(sizeTarget)
        };
      }
    }

    if (!packagingTypeData) return null;
    data.designAmount = packagingTypeData?.hasCustomDesign ? Number(designAmount) : 0;
    data.quantity = quantity;

    data.options = [];
    for (const selectedOption of selectedOptions) {
      const optionTarget = apiData.pouchOptions.find((e) => e.uuid === selectedOption);
      if (optionTarget) {
        data.options.push({
          id: optionTarget.uuid,
          name: getTranslationFromLangObj(optionTarget.nameTrans)
        });
      }
    }

    return data as ISummaryData;
  }
);

// --------- selectors

export default wizardSlice.reducer;
