import React from "react";

import { ReactComponent as WarnIconSvg } from "../../assets/images/icons/warn-icon.svg";

interface Props {
  warning: string;
}

export const WarnTooltip: React.FC<Props> = ({ warning }) => {
  return (
    <div className="__react_component_tooltip__warning-tooltip">
      <WarnIconSvg data-tip={warning} />
    </div>
  );
};
