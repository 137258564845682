import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { selectPackagingTypeData } from "../store/wizard/wizardSlice";

export const useHasCustomDesign = (packageTypeId?: string) => {
  const [hasCustomDesign, setHasCustomDesign] = useState(false);
  const selectedPackagingType = useSelector(selectPackagingTypeData(packageTypeId));

  useEffect(() => {
    if (selectedPackagingType) {
      setHasCustomDesign(selectedPackagingType.hasCustomDesign);
    }
  }, [selectedPackagingType]);

  return hasCustomDesign;
};
