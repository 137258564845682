import React, { useEffect } from "react";

import classNames from "classnames";
import ReactTooltip from "react-tooltip";

import { Icon } from "../../common/Icon";
import { FormGroup } from "../FormGroup";
import { FormGroupTooltip } from "../FormGroupTooltip";

interface Props {
  value: boolean;
  label: any;
  subLabel?: any;
  appendix?: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  tooltip?: string;
  size?: "sm" | "md";
  disabled: boolean;
}

export const Checkbox: React.FC<Props> = ({ size, value, onChange, label, subLabel, appendix, tooltip, disabled }) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <FormGroup>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        className={classNames("checkbox-container", {
          "checkbox-container--checked": value,
          "checkbox-container--sm": size === "sm",
          "checkbox-container--disabled": disabled
        })}
      >
        <div className="checkbox-container__label">
          {appendix ? <div className="checkbox-container__label__appendix">{appendix}</div> : ""}
          <div className="checkbox-container__label__text">
            <span>{label}</span>
            {subLabel ? <span>{subLabel}</span> : null}
          </div>
        </div>
        <input type="checkbox" checked={value} disabled={disabled} onChange={onChange} />
        <span className="checkmark">
          <Icon name="check-2" />
        </span>
        {tooltip ? <FormGroupTooltip tooltip={tooltip} /> : ""}
      </label>
    </FormGroup>
  );
};
