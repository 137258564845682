import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Loader from "../components/common/Loader";
import { LocalStorageService } from "../services/LocalStorageService";
import { setSpecificStepDataValue, resetState } from "../store/wizard/wizardSlice";
import { WizardStepType } from "../types/wizard";

export const SaveToken = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!token) {
      return;
    }

    LocalStorageService.reset();
    dispatch(resetState());

    dispatch(
      setSpecificStepDataValue({
        type: WizardStepType.Order,
        option: "options",
        subOption: "customerToken",
        value: token
      })
    );
    LocalStorageService.saveCustomerToken(token);

    navigate(`/`);
  }, [token]);

  return (
    <div>
      <Loader show fullBlind />
    </div>
  );
};
